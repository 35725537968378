import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App';
import { useNavigate, useParams } from 'react-router-dom';

export default function User(props) {
  const params = useParams()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [dce, setDCE] = useState('')
  const [role, setRole] = useState('M')
  const [section, setSection] = useState('---')
  const [extraSections, setExtraSections] = useState([])
  const [status, setStatus] = useState('---')
  const [mGStanding, setMGStanding] = useState(false)
  const [exempt, setExempt] = useState(false)
  const [exemptDesc, setExemptDesc] = useState('')
  const [gStanding, setGStanding] = useState(false)
  const [fGStanding, setFGStanding] = useState(false)
  const [userEdit, setUserEdit] = useState(false)
  const [remove, setRemove] = useState(false)
  const [events, setEvents] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (params.selectedUser != null && params.selectedUser !== undefined) {
      let url = `${props.constants.hostname}/api/secretary/user?editUser=${params.selectedUser}`;
      fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
      })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setFirstName(data[0].FirstName) 
        setLastName(data[0].LastName)
        setEmail(data[0].Email) 
        setDCE(data[0].DCE) 
        setRole(data[0].Role)
        setSection(data[0].SECTION) 
        setStatus(data[0].Status) 
        setMGStanding(data[0].MGStanding)
        setExempt(data[0].Exempt) 
        setExemptDesc(data[0].ExemptDesc)
        setGStanding(data[0].GStanding) 
        setFGStanding(data[0].FGStanding)
      })
      .catch((error) => { console.error(error); })
      url = `${props.constants.hostname}/api/secretary/userAttendance?userID=${params.selectedUser}`;
      fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
      })
      .then((res) => {
          if (!res.ok) {
              return false;
          }
          return res.json();
      })
      .then((data) => {
          if (!data) { return; }
          setEvents(data)
      })
      .catch((error) => { console.error(error); });
      url = `${props.constants.hostname}/api/secretary/userSections?user=${params.selectedUser}`;
      fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
      })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        if (!data) { return; }
        setExtraSections(data)
      })
      .catch((error) => { console.error(error); });
    }
  }, [])

  function handleDownload() {
    const csvContent = `data:text/csv;charset=utf-8,${events.map((e) => `${e.Name},${e.DAY},${e.Type},${e.Semester}`).join('\n')}`
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    const now = new Date();
    link.setAttribute('download', `EventAttendance-${lastName}-${firstName}-(${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}).csv`);
    document.body.appendChild(link);
    link.click();
  }

  function confirm() {
    if (window.confirm('Delete this user?')) {
      const url = `${props.constants.hostname}/api/secretary/removeUser?userInfo=${dce}`;
      fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`},
        method: 'DELETE',
      })
      .then((res) => {
        setRemove(true)
        if (!res.okay) {
          return false;
        }
        return res.json();
      })
      .catch((error) => { console.error(error); });
    }
  }

  if(userEdit) {
    navigate(`/secretary/userEdit/${dce}`)
  }
  if(remove) {
    navigate('/secretary/users')
  }

  return (
    <div>
      <h3>User Profile for {lastName}, {firstName}</h3>
      <div className='secBarNav'>
        <FontAwesomeIcon
          icon='user-edit'
          className='faEdit'
          onClick={() => setUserEdit(true)}
        />
        <FontAwesomeIcon
          icon='file-download'
          className='faEdit'
          style={{marginLeft: '0px'}}
          onClick={() => handleDownload()}
        />
        <FontAwesomeIcon
          icon='trash-alt'
          className='faDelete'
          onClick={() => confirm()}
        />
      </div>
      <hr />
      <div className='userProfile'>
        <label className='secretaryLabel'>
          First Name: {firstName}
        </label>
        <label className='secretaryLabel'>
          Last Name: {lastName}
        </label>
        <label className='secretaryLabel'>
          Email: {email}
        </label>
        <label className='secretaryLabel'>
          DCE: {dce}
        </label>
        <label className='secretaryLabel'>
          Role: {role}
        </label>
        <label className='secretaryLabel'>
          Section: {section}
        </label>
        <label className='secretaryLabel'>
          Other Sections: {extraSections.map((s) => `${s.SECTION}, `)}
        </label>
        <label className='secretaryLabel'>
          Status: {status}
        </label>
        <label className='secretaryLabel'>
          Manually Added Good Standing: {mGStanding === 1 ? 'true' : 'false'}
        </label>
        <label className='secretaryLabel'>
          Exempt: {exempt === 1 ? 'true' : 'false'}
        </label>
        <label className='secretaryLabel'>
          Exemption Description: {exemptDesc}
        </label>
        <label className='secretaryLabel'>
          Good Standing (Current Semester): {gStanding === 1 ? 'true' : 'false'}
        </label>
        <label className='secretaryLabel'>
          Good Standing (Future Semester): {fGStanding === 1 ? 'true' : 'false'}
        </label>
      </div>
      <h4>Individual User Attendance: ({events.length})</h4>
      <table className='userTable'>
        <thead>
          <tr className='userRow userHeaderRow'>
            <th className='userHeader'>Name</th>
            <th className='userHeader'>Date</th>
            <th className='userHeader'>Type</th>
            <th className='userHeader'>Semester</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event) =>
            <tr key={event.Eid} className='userRow' onClick={() => setUserEdit(event.Eid)}>
              <td className='userEntrySeparate'>{event.Name}</td>
              <td className='userEntrySeparate'>{event.DAY}</td>
              <td className='userEntrySeparate'>{event.Type}</td>
              <td className='userEntrySeparate'>{event.Semester}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}