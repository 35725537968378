import React from 'react';

export default function History0910() {
  return (
    <div className='History'>
      <h2>History 2009-2010</h2>
      <h2>E-Board:</h2>
        <p><b>President: </b>Hans Melhus</p>
        <p><b>Vice President: </b>Chris Donnelly</p>
        <p><b>Vice President: </b>Glenn Levesque</p>
        <p><b>Secretary: </b>Chris Gastle</p>
        <p><b>Treasurer: </b>Erin Miller</p>
        <p><b>Major Conductor: </b>Drew Zemke</p>
        <p><b>Minor Conductor: </b>Morgan Killik</p>
        <p><i>Major Conductor Attire:</i> Black Suit Jacket over a navy blue shirt, Black Pants, tiger print tie, Black top hat with Bare Hands</p>
        <p><i>Minor Conductor Attire:</i> White Dress shirt, black pants, Tiger Print tie, bare hands</p>

      <h3>Beast Status:</h3>
        <p>Chris Bentivenga (Tuba): Earthquake</p>
        <p>Pat McVeety-Hill (Tuba): Earthquake</p>
        <p>Rachel Rattner (Trombone): Super Mario Brothers</p>
        <p>Todd Bello (Tuba): Earthquake</p>

      <h3>GOD Status</h3>
        <p>Karen Smith (Trombone): Super Mario Brothers</p>

      <h3>Graduating Members</h3>
        <p>Amanda Preske (Clarinet)</p>
        <p>Bobby Brown (Trombone)</p>
        <p>Ken Krug (Trumpet</p>
        <p>Meg Maksymowich (Trumpet)</p>
        <p>Rachel Rattner (Trombone)</p>
        <p>Sam Yurick (French Horn)</p>
        <p>Shannon Patrick (Tenor Saxophone)</p>
        <p>Spencer Herendeen (Trumpet)</p>

      <h3>New Music</h3>
        <p>Apache</p>
        <p>Battle star Galactica</p>
        <p>Get Ready for This</p>
        <p>I'm a Man</p>
        <p>Pink Panther</p>
        <p>Tear the Roof Off This Sucker</p>

      <h3>Accomplishments and Achievements</h3>
        <p>Played at all Home Hockey Games</p>
        <p>Played at a Pittsford Panthers Hockey Game</p>
        <p>Played a Double Header</p>
        <p>Played on the Tiger Steps</p>
        <p>Played in freezing temperatures</p>
        <p>Travelled with Team to Albany NY for NCAA Regional Playoffs</p>
        <p>Travelled with Team to Detroit MI for NCAA Frozen Four </p>
        <p>Voted Student Government Performing Arts Club of the Year</p>
        <p>Had Outdoor Rehearsals</p>
        <p>Seen and Heard on Three Nationally Televised Games</p>
        <p><b>Max Attendance at an Event: 68</b></p>
    </div>
  );
}
