import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import './css/index.css';
import * as serviceWorker from './serviceWorker';
import config from './config';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const firebase = initializeApp(config);
export const fireauth = getAuth(firebase);

let port = `:${process.env.REACT_APP_PORT || 3030}`;
const hostname = `//${window.location.hostname}${port}`;
if (port === ':Heroku') {
  port = '';
}

const initialState = {
  constants: {
    roles: {
      admin: 'admin',
      president: 'president',
      conductor: 'conductor',
      secretary: 'secretary',
      vicePresident: 'vicePresident',
      treasurer: 'treasurer',
      member: 'member',
      nonMember: 'nonMember',
      alumni: 'alumni',
      sectionLeader: 'sectionLeader',
    },
    hostname,
  },
  userRole: null,
  user: null,
};

export function authRole(currentRole, target) {
  const { roles } = initialState.constants;
  if (currentRole === target) {
    return true;
  }
  switch (currentRole) {
    case roles.admin:
      return authRole(roles.president, target);
    case roles.president:
      return authRole(roles.secretary, target)
        || authRole(roles.conductor, target)
        || authRole(roles.treasurer, target)
        || authRole(roles.vicePresident, target)
        || authRole(roles.sectionLeader, target);
    case roles.secretary:
      return authRole(roles.member, target);
    case roles.conductor:
      return authRole(roles.member, target);
    case roles.treasurer:
      return authRole(roles.member, target);
    case roles.vicePresident:
      return authRole(roles.member, target);
    case roles.sectionLeader:
      return authRole(roles.member, target);
    case roles.member:
      return authRole(roles.alumni, target);
    default:
      return false;
  }
}

const render = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <BrowserRouter>
      <App state={initialState}/>
    </BrowserRouter>
  );
  console.log('Rendered the Application');
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
