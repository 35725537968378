import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App';
import { useNavigate } from 'react-router-dom';

export default function Events(props) {
  const [events, setEvents] = useState([])
  const [filtered, setFiltered] = useState([])
  const [eventPage, setEventPage] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const url = `${props.constants.hostname}/api/secretary/events`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
        if (!res.ok) {
            return false;
        }
        return res.json();
    })
    .then((data) => {
        if (!data) { return; }
        setEvents(data)
        setFiltered(data)
    })
    .catch((error) => { console.error(error); });
  }, [])

  function handleDownload() {
    const csvContent = `data:text/csv;charset=utf-8,${events.map((e) => `${e.Name},${e.DAY},${e.Type},${e.Semester}`).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    const now = new Date();
    link.setAttribute('download', `Events-(${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}).csv`);
    document.body.appendChild(link);
    link.click();
  }

  function rowClick(EID) {
    setEventPage(EID)
  }

  if(eventPage !== '') {
    navigate(`/secretary/event/${eventPage}`)
  }

  return (
    <div>
      <div className='secBarNav'>
        <FontAwesomeIcon
          icon='file-download'
          className='faEdit'
          onClick={() => handleDownload()}
        />
      </div>
      <hr />
      <h3>Events</h3>
      <div className='tableDiv'>
        <table className='userTable'>
          <thead>
            <tr className='userRow userHeaderRow'>
              <th className='userHeader'>Name</th>
              <th className='userHeader'>Date</th>
              <th className='userHeader'>Type</th>
              <th className='userHeader'>Semester</th>
              <th className='userHeader'>Counts as a Rehearsal?</th>
            </tr>
          </thead>
          <tbody>
            {filtered.map((event) =>
              <tr key={event.Eid} className='userRow' onClick={() => rowClick(event.Eid)}>
                <td className='userEntrySeparate'>{event.Name}</td>
                <td className='userEntrySeparate'>{event.DAY}</td>
                <td className='userEntrySeparate'>{event.Type}</td>
                <td className='userEntrySeparate'>{event.Semester}</td>
                <td className='userEntrySeparate'>{event.Rehearsal === 'Y' ? 'Yes' : 'No'}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
