const config = {
  apiKey: 'AIzaSyBxTZq1WEvgO_6PRPRT5D5KJsisWqYESGI',
  authDomain: 'ritpepbandwebsite.firebaseapp.com',
  databaseURL: 'https://ritpepbandwebsite.firebaseio.com',
  projectId: 'ritpepbandwebsite',
  storageBucket: '',
  messagingSenderId: '546792179549',
  appId: '1:546792179549:web:d38b7e5df5d9c7dc',
};

export default config;
