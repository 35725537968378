import React, { useEffect, useState } from 'react';
import { USERID } from '../../App';
import { Link } from 'react-router-dom';
import '../../../css/voting.css';

export default function Results(props) {
    const [totalGoodStanding, setTotalGoodStanding] = useState(0)
    const [quorums, setQuorums] = useState([])
    const [voteTallies, setVoteTallies] = useState([])

    useEffect(() => {
        const url = `${props.constants.hostname}/api/voting/results`;
        fetch(url, {
            headers: { Authorization: `${localStorage.getItem(USERID)}`},
        })
        .then((res) => {
          if (!res.ok) {
            return false;
          }
          return res.json();
        })
        .then((data) => {
          if (!data) { 
            return; 
          }
          setTotalGoodStanding(data.totalGoodStanding[0].numGoodStanding)
          setVoteTallies(data.votes)
          setQuorums(data.quorums)
        })
        .catch((error) => { console.log(error); });
    }, [])

    const electionArray = {}
    for(const election of quorums) {
        electionArray[election.ElectionName] = {Election: election, Candidates: []}
    }
    for(const candidate of voteTallies) {
        electionArray[candidate.ElectionName].Candidates.push(candidate)
    }
    return (
        <div>
            <Link
                to='/vote'
                className='secBarLink'
            >
                <p className='secBarText'>Back to "Election Home"</p>
            </Link>
            <h3>Results</h3>
            <p>Total Good Standing Members: {totalGoodStanding}</p>
            <p>Votes required to hit Quorom: {Math.ceil(totalGoodStanding / 2)}</p>
            <h4>Election Results</h4>
            <div style={{display: 'flex', width: '80%', flexWrap: 'wrap'}}>
                {Object.values(electionArray).map((e) =>
                    <div className='resultsDiv'>
                        <p>{e.Election.ElectionName} - {e.Election.votedCount} votes</p>
                        <table className='resultsTable'>
                            <tr className='resultsCandidateRow'>
                                <th>Candidate</th>
                                <th>Points</th>
                                <th>Abstains</th>
                            </tr>
                            {e.Candidates.map((c) => 
                                <tr className='resultsCandidateRow'>
                                    <td>{c.name}</td>
                                    <td>{c.PointsEarned}</td>
                                    <td>{c.Abstains}</td>
                                </tr>
                            )}
                        </table>
                    </div>
                )}
            </div>
        </div>
    )
}
