import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import '../../../css/qdb.css';
import { USERID } from '../../App';
import { authRole } from '../../..';

export default function ViewFeedback(props) {
  const navigate = useNavigate()
  if(!(authRole(props.userRole, props.constants.roles.president)
    || authRole(props.userRole, props.constants.roles.secretary)
    || authRole(props.userRole, props.constants.roles.conductor)
    || authRole(props.userRole, props.constants.roles.vicePresident)
    || authRole(props.userRole, props.constants.roles.treasurer)
    || authRole(props.userRole, props.constants.roles.sectionLeader))) {
      navigate('/404')
  }

  const [feedback, setFeedback] = useState([])
  const [filtered, setFiltered] = useState([])

  useEffect(() => {
    const url = `${props.constants.hostname}/api/feedback/getFeedback`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then((data) => {
      if (!data) { return; }
      setFeedback(data)
      setFiltered(data)
    })
    .catch((error) => { console.error(error); });
  }, [])

  function handleSearch(event) {
    const filteredFeed = [];
    const searchVal = event.target.value.toLowerCase();
    feedback.filter((feed) => {
      if (feed.Title.toLowerCase().includes(searchVal)
        || feed.Content.toLowerCase().includes(searchVal)
      ) {
        filteredFeed.push(feed);
      }
      return filteredFeed;
    })
    setFiltered(filteredFeed)
  }

  return (
    <div id='Qdb'>
      <h2>Feedback</h2>
        <div className={`${props.cssOff ? '' : 'oneline'}`}>
          <Link to='/feedback' className={`${props.cssOff ? '' : 'secBarLink'}`}>
            <div className={`${props.cssOff ? '' : 'oneline'}`}>
              <p className={`${props.cssOff ? '' : 'secBarText'}`}>
                Back to Feedback
              </p>
            </div>
          </Link>
        </div>
      <hr/>
      <br/>
      <div className={`${props.cssOff ? '' : 'oneline center'}`}>
        <p className={`${props.cssOff ? '' : 'searchTag'}`}><b>Search:</b></p>
        <input
          onChange={(e) => handleSearch(e)}
          className={`${props.cssOff ? '' : 'quoteSearch'}`}
          placeholder='Search for Feedback...'
        />
      </div>
      <div className={`${props.cssOff ? '' : 'qdbTables'}`}>
        {filtered.map((feed) =>
          <div id={feed.FID} key={feed.FID}>
            <table className={`${props.cssOff ? '' : 'QDBTable'}`}>
              <tbody>
                <tr className={`${props.cssOff ? '' : 'QDBTitle'}`}>
                  <th colSpan='2'>{feed.Title}</th>
                </tr>
                <tr className={`${props.cssOff ? '' : 'QDBRow quoteRow'}`}>
                  <td colSpan='2' className={`${props.cssOff ? '' : 'quote multiline'}`}>
                    <ReactMarkdown children={feed.Content} />
                  </td>
                </tr>
                <tr className={`${props.cssOff ? '' : 'QDBRow secondary'}`}>
                  <td className={`${props.cssOff ? '' : 'quotePerson'}`}>-{feed.Date}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
