import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App';
import { useNavigate } from 'react-router-dom';

export default function UserToEvent(props) {
  let section = ''
  const [error, setError] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFiltered] = useState([])
  const [usersGoing, setGoing] = useState([])
  const [selectedUser, setSelected] = useState('')
  const [userDCEGoing, setDCEGoing] = useState([])
  const [events, setEvents] = useState([])
  const [eventID, setEID] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    let url = `${props.constants.hostname}/api/secretary/users`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
        if (!res.ok) {
            return false;
        }
        return res.json();
    })
    .then((data) => {
        if (!data) { return; }
        setUsers(data)
        setFiltered(data)
    })
    .catch((e) => { console.error(e); });
    url = `${props.constants.hostname}/api/secretary/events`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
        if (!res.ok) {
            return false;
        }
        return res.json();
    })
    .then((data) => {
        if (!data) { return; }
        setEvents(data)
    })
    .catch((e) => { console.error(e); });
  }, [])

  function confirm(user, index) {
    if (window.confirm('Delete this user from the event attendance list?')) {
      const tempArray = usersGoing;
      tempArray.splice(index, 1)
      const tempArray2 = userDCEGoing;
      tempArray2.splice(user, 1)
      setGoing(tempArray)
      setDCEGoing(tempArray2)
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    const errorInput = [];
    const errorDCEInput = [];
    let counter = 0;
    const elementsToAdd = usersGoing.length
    usersGoing.forEach((newUser, idx) => {
      const url = `${props.constants.hostname}/api/secretary/addAttendant`;
      fetch(url, {
        body: JSON.stringify({ userInfo: newUser.DCE, eventID: eventID }),
        headers: {
          Authorization: `${localStorage.getItem(USERID)}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then((res) => {
        if ((elementsToAdd - 1) === idx) {
          if (!res.ok) {
            errorInput[counter] = newUser;
            errorDCEInput[counter] = newUser.DCE;
            counter++;
          }
          if (errorInput.length !== 0) {
            section = '';
            setDCEGoing(errorDCEInput)
            setGoing(errorInput)
            setError(true)
          } else {
            setSubmit(true)
          }
        }
        else if (!res.ok) {
          throw new Error('Entry already exists within the system')
        }
      })
      .catch((e) => {
        console.error(e);
        errorInput[counter] = newUser;
        errorDCEInput[counter] = newUser.DCE;
        counter++;
      })
    })
  }

  function addUserGoing(event) {
    event.preventDefault();
    for(const user of users) {
      if(user.DCE === selectedUser) {
        if(!userDCEGoing.includes(user.DCE)) {
          const tempGoing = [...usersGoing];
          const tempDCEGoing = userDCEGoing;
          tempGoing.push(user);
          tempDCEGoing.push(user.DCE);
          section = '';
          setGoing(tempGoing)
          setDCEGoing(tempDCEGoing)
          setSelected('')
          break;
        }
        else {
          console.error('User already going');
          alert('User already added to event');
          setSelected('')
        }
      }
    }
  }

  function generateRow(user, index) {
    if (section === user.SECTION) {
      return (
        <tr key={user.DCE} className='userRow' style={{cursor: 'auto'}}>
          <td className='userEntry'></td>
          <td className='userEntry'>{user.LastName}</td>
          <td className='userEntry'>{user.FirstName}</td>
          <td className='userEntry'>
            <FontAwesomeIcon
              icon='trash-alt'
              className='faDelete'
              onClick={() => confirm(user.DCE, index)}
            />
          </td>
        </tr>
      );
    } else {
      section = user.SECTION;
      return (
        <tr key={user.DCE} className='userRow' style={{cursor: 'auto'}}>
          <td className='userEntrySeparate'>{user.SECTION}</td>
          <td className='userEntrySeparate'>{user.LastName}</td>
          <td className='userEntrySeparate'>{user.FirstName}</td>
          <td className='userEntry'>
            <FontAwesomeIcon
              icon='trash-alt'
              className='faDelete'
              onClick={() => confirm(user.DCE, index)}
            />
          </td>
        </tr>
      );
    }
  }

  if(submit) {
    navigate('/secretary/events')
  }

  section = undefined;
  return (
    <div>
      {error ?
        <h2>The following elements could not be added. Please check to ensure they aren't already apart of the event</h2>
        :
        null
      }
      <h3>Add Users to Event</h3>
      <hr />
      <h4>Event Selection</h4>
      <form>
        <label className='secretaryLabel'>
          Event:
          <select
            value={eventID}
            onChange={(e) => setEID(e.target.value)}
            className='secretaryInput'
          >
            <option default value={0}>---</option>
            {
              events.map((event) =>
                <option key={event.Eid} value={event.Eid}>{event.Name}</option>
              )
            }
          </select>
        </label>
      </form>
      <h4>User(s) Selection</h4>
      <form>
        <label className='secretaryLabel'>
          User:
          <input
            value={selectedUser}
            onChange={(e) => setSelected(e.target.value)}
            className='secretaryInput'
            list='usersList'
          />
          <datalist id='usersList'>
            {
              filteredUsers.map((user) =>
                <option key={user.DCE} value={user.DCE}>{user.LastName},{user.FirstName}</option>
              )
            }
          </datalist>
          <button
            onClick={addUserGoing}
            className={'secretarySelect'}
          >
            Select Attendee
          </button>
        </label>
      </form>
      <h4>Selected Users ({usersGoing.length})</h4>
      <button
        onClick={handleSubmit}
        className='secretarySubmit'
      >
        Submit List
      </button>
      <div className='tableDiv'>
        <table className='userTable'>
          <thead>
            <tr className='userRow userHeaderRow'>
              <th className='userHeader'>Section</th>
              <th className='userHeader'>Last Name</th>
              <th className='userHeader'>First Name</th>
              <th className='userHeader'>Delete</th>
            </tr>
          </thead>
          <tbody>
            {usersGoing.map((user, index) =>
              generateRow(user, index)
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}