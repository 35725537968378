import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../App'
import '../../css/music.css';
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function Music(props) {
  const params = useParams()
  const [song, setSong] = useState([])
  const [redirect, setRedirect] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const url = `${props.constants.hostname}/api/music/show?slug=${params.song}`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
      .then((res) => {
        if (!res.ok) {
          setRedirect(true)
          return false;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) { return; }
        setSong(data[0])
      })
      .catch((error) => { console.error(error); });
  }, [])

  function renderParts(part) {
    return (
      <tr key={part} className={`${props.cssOff ? '' : 'musicRow'}`}>
        <td className={`${props.cssOff ? '' : 'musicEntry'}`}>
          {part.split('.jpg')[0]}
        </td>
        <td className={`${props.cssOff ? '' : 'musicEntry'}`}>
          <Link
            target='_blank'
            to={`/music/${song.Title}/show/${part}`}
          >
            <div className={`${props.cssOff ? '' : 'oneline center clickable'}`}>
              <FontAwesomeIcon icon='image' className={`${props.cssOff ? '' : 'faImage'}`}/>
              {part.split('.jpg')[0]}
            </div>
          </Link>
        </td>
        <td className={`${props.cssOff ? '' : 'musicEntry'}`}>
          <Link
            target='_blank'
            to={`/music/${song.Title}/show/${`${part.split('.')[0]}.pdf`}`}
          >
            <div className={`${props.cssOff ? '' : 'oneline center  clickable'}`}>
              <FontAwesomeIcon icon='image' className={`${props.cssOff ? '' : 'faImage'}`}/>
              {part.split('.jpg')[0]}
            </div>
          </Link>
        </td>
      </tr>
    );
  }

  if(redirect) {
    navigate('/404')
  }

  return (
    <div className={`${props.cssOff ? '' : 'music'}`}>
      <h3 className={`${props.cssOff ? '' : 'musicTitle'}`}>{song.Title}</h3>
      <table style={{width: '75%'}}>
        <tr>
          <th style={{width: '25%'}}>
            Audio File
          </th>
          <th style={{width: '30px'}}>
            {/* empty */}
          </th>
          <th style={{width: '75%', textAlign: 'left'}}>
            Special Notes
          </th>
        </tr>
        <tr>
          <td style={{width: '25%'}}>
            {song.Mp3 ?
              <audio controls>
                <source
                  src={`${props.constants.hostname}/api/music/mp3?title=${song.Title}&user=${localStorage.getItem(USERID)}`}
                  type='audio/mpeg'
                ></source>
              Your browser does not support the audio element.
              </audio>
              :
              <p>None</p>
            }
          </td>
          <td style={{width: '30px'}}>
            {/* empty */}
          </td>
          <td style={{width: '75%'}}>
            {song.PublicComment !== '' ?
              <p>{song.PublicComment}</p>
              :
              <p>None</p>
            }
          </td>
        </tr>
      </table>
      <h3>Parts</h3>
      {song.length !== 0 ?
        <div className={`${props.cssOff ? '' : 'musicDiv'}`}>
          <table className={`${props.cssOff ? '' : 'musicTable'}`}>
            <thead>
              <tr className={`${props.cssOff ? '' : 'musicRow'}`}>
                <th className={`${props.cssOff ? '' : 'musicHeader'}`}>Section</th>
                <th className={`${props.cssOff ? '' : 'musicHeader'}`}>JPG</th>
                <th className={`${props.cssOff ? '' : 'musicHeader'}`}>PDF</th>
              </tr>
            </thead>
            <tbody>
              {song.Jpg.map((s) => renderParts(s))}
            </tbody>
          </table>
        </div>
        :
        null
        }
    </div>
  );
}