import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../App'
import '../../css/home.css';
import { Link } from 'react-router-dom';

export default function Home(props) {
  const [songs, setSongs] = useState([])
  const [filtered, setFiltered] = useState([])

  useEffect(() => {
    const url = `${props.constants.hostname}/api/music?status=Public`;
    fetch(url, {
      headers: { Authorization: `${localStorage.getItem(USERID)}` }
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) { return; }
        setSongs(data)
        setFiltered(data)
      })
      .catch((error) => { console.error(error); });
  }, [])

  function handleSearch(event) {
    const filteredSongs = [];
    let idx = 0;
    const searchVal = event.target.value.toLowerCase();
    songs.filter((song) => {
      if (song.Title.toLowerCase().includes(searchVal)) {
        filteredSongs[idx] = song;
        idx++;
      }
      return filteredSongs;
    })
    setFiltered(filteredSongs)
  }

  return (
    <div>
      <div id='Home' className={`${props.cssOff ? '' : 'home-right-block'}`}>
        <div className={`${props.cssOff ? '' : 'home-left-block'}`}>
          <div id='Songs'>
            <h3 className={`${props.cssOff ? '' : 't2'}`}>Music</h3>
            <input
              onChange={(e) => handleSearch(e)}
              className={`${props.cssOff ? '' : 'songSearch'}`}
              placeholder='Search for songs...'
            />
            <div id='songList'>
                {filtered.map((song) =>
                  <div key={song.Slug}>
                      <div className={`${props.cssOff ? '' : 'songLink'}`}>
                        <Link
                          to={`/music/${song.Slug}`}
                          className={`${props.cssOff ? '' : 'songColor'}`}
                        >
                          <FontAwesomeIcon icon='file-alt' className={`${props.cssOff ? '' : 'faFile'}`} />
                          {song.Title}
                        </Link>
                      </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className={`${props.cssOff ? '' : 'home-right-block'}`}>
          <div id='Calendar'>
            <h3 className={`${props.cssOff ? '' : 't2'}`}>Calendar</h3>
            <iframe
              id='calendarBlock'
              title='calendar'
              src='https://calendar.google.com/calendar/embed?src=ritpepband%40gmail.com&ctz=America%2FNew_York&showTitle=0&color=%23B1440E&showPrint=0&showCalendars=0&showTabs=0&showTz=0'
              style={{ border: '0' }}
              width='min-width'
              height='min-height'
            >
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
