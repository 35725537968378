import React, { useState } from 'react';
import { blankCheck } from '../../Errors';
import { USERID } from '../../App';
import { Link, useNavigate } from 'react-router-dom';

export default function AddQuote(props) {
  const [title, setTitle] = useState('')
  const [person, setPerson] = useState('')
  const [quote, setQuote] = useState('')
  const [submit, setSubmit] = useState(false)
  const navigate = useNavigate()

  function errorCheck() {
    if (blankCheck(title)) {
      console.error('Blank field: Title')
      alert('Blank field: Title')
      return false;
    }
    if (blankCheck(person)) {
      console.error('Blank field: Person')
      alert('Blank field: Person')
      return false;
    }
    if(blankCheck(quote)) {
      console.error('Blank field: Quote')
      alert('Blank field: Quote')
      return false;
    }
    return true;
  }

  function getDate() {
    const date = new Date();
    const year = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    const time = `${date.getHours()}:${date.getMinutes()}`
    return `${year}T${time}`
  }

  function submitData() {
    if(errorCheck()) {
      const quoteObject = {
        Title: title,
        Date: getDate(),
        Status: 'Private',
        Person: person,
        Quote: quote,
      }
      const url = `${props.constants.hostname}/api/qdb/addQuote`;
      fetch(url, {
        body: JSON.stringify({ quote: quoteObject }),
        headers: {
          Authorization: `${localStorage.getItem(USERID)}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        else {
          setSubmit(true)
          return true;
        }
      })
      .catch((e) => { console.error(e); })
    }
  }

  if(submit) {
    navigate('/qdb/home')
  }

  return (
    <div>
      <h2>Add Quote to QDB</h2>
      <div className='secBarNav'>
        <Link to='/qdb/home' className='secBarLink'>
          <p className='secBarText'>Back to QDB Home</p>
        </Link>
      </div>
      <hr />
      <form className='userForm'>
        <label
          className='secretaryLabel'
          style={{marginTop: '.5em', marginBottom: '1em'}}
        >
          Note:
          <a
            href='https://www.markdownguide.org/cheat-sheet/'
            target='_blank'
            rel='noopener noreferrer'
            className='secBarText'
            style={{marginLeft: '3px', marginRight: '3px'}}
          >
            Markdown
          </a>
          is now supported for quotes
        </label>
        <table>
          <tbody>
            <tr>
              <td>
                <label className='secretaryLabel'>
                  Title:
                </label>
              </td>
              <td>
                <input
                  type='text'
                  name='Title'
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  className='qdbInput'
                  maxLength='100'
                />
              </td>
            </tr>
            <tr>
              <td>
                <label className='secretaryLabel'>
                  Person:
                </label>
              </td>
              <td>
                <input
                  type='text'
                  name='Person'
                  onChange={(e) => setPerson(e.target.value)}
                  value={person}
                  className='qdbInput'
                  maxLength='50'
                />
              </td>
            </tr>
            <tr>
              <td>
                <label className='secretaryLabel'>
                  Quote:
                </label>
              </td>
              <td>
                <textarea
                  type='text'
                  name='FirstName'
                  onChange={(e) => setQuote(e.target.value)}
                  value={quote}
                  className='quoteTextArea'
                  maxLength='255'
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <button
        onClick={submitData}
        className='secretarySubmit'
      >
        Submit Quote
      </button>
    </div>
  );
}