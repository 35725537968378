import React from 'react';

export default function History1617() {
  return (
    <div className='History'>
      <h2>History 2016-2017</h2>
      <h2>E-Board:</h2>
        <p><b>President: </b>Emily O’Conner</p>
        <p><b>Vice President: </b>Elise Walsh</p>
        <p><b>Vice President: </b>Alan Kuffner</p>
        <p><b>Secretary: </b>Amanda Meade</p>
        <p><b>Treasurer: </b>David Routier</p>
        <p><b>Conductor: </b>Brian Lake</p>
        <p><i>Conductor Attire:</i> black shirt, black pants, trombone pin</p>

      <h3>Accomplishments and Achievements</h3>
        <p>Played at a WNY Flash Soccer Game</p>
        <p>Played for Dr. Destler’s Tiger Walk</p>
        <p>Recorded Eye of the Tiger for Dr. Destler’s retirement ceremony</p>
        <p>Designed a new,larger banner with help from Corner Crew</p>
        <p>Played for NCAA D3 Lacrosse Championship at Gillette Stadium in Foxborough, MA</p>
        <p>Traveled to Canisuis to support men’s team</p>
        <p><b>Max Attendance at an Event: ??</b></p>
    </div>
  );
}
