import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';

import History0708 from './HistoryPages/2007-2008';
import History0607 from './HistoryPages/2006-2007';
import History0809 from './HistoryPages/2008-2009';
import History0910 from './HistoryPages/2009-2010';
import History1011 from './HistoryPages/2010-2011';
import History1112 from './HistoryPages/2011-2012';
import History1213 from './HistoryPages/2012-2013';
import History1314 from './HistoryPages/2013-2014';
import History1415 from './HistoryPages/2014-2015';
import History1516 from './HistoryPages/2015-2016';
import History1617 from './HistoryPages/2016-2017';
import History1718 from './HistoryPages/2017-2018';
import History1819 from './HistoryPages/2018-2019';
import History1920 from './HistoryPages/2019-2020';
import History2021 from './HistoryPages/2020-2021';
import History2122 from './HistoryPages/2021-2022';
import History2223 from './HistoryPages/2022-2023';
import History2324 from './HistoryPages/2023-2024';
import NotFound from './NotFound';
import '../../css/history.css'

export default function History(props) {
  return (
    <div id="HistoryList">
        <div>
          <ul className={`${props.cssOff ? '' : 'historyController'}`}>
            <Link to='/history/2006-2007' id="historyLink"><li>2006-2007</li></Link>
            <Link to='/history/2007-2008' id="historyLink"><li>2007-2008</li></Link>
            <Link to='/history/2008-2009' id="historyLink"><li>2008-2009</li></Link>
            <Link to='/history/2009-2010' id="historyLink"><li>2009-2010</li></Link>
            <Link to='/history/2010-2011' id="historyLink"><li>2010-2011</li></Link>
            <Link to='/history/2011-2012' id="historyLink"><li>2011-2012</li></Link>
            <Link to='/history/2012-2013' id="historyLink"><li>2012-2013</li></Link>
            <Link to='/history/2013-2014' id="historyLink"><li>2013-2014</li></Link>
            <Link to='/history/2014-2015' id="historyLink"><li>2014-2015</li></Link>
            <Link to='/history/2015-2016' id="historyLink"><li>2015-2016</li></Link>
          </ul>
        </div>
        <div>
          <ul className={`${props.cssOff ? '' : 'historyController'}`}>
            <Link to='/history/2016-2017' id="historyLink"><li>2016-2017</li></Link>
            <Link to='/history/2017-2018' id="historyLink"><li>2017-2018</li></Link>
            <Link to='/history/2018-2019' id="historyLink"><li>2018-2019</li></Link>
            <Link to='/history/2019-2020' id="historyLink"><li>2019-2020</li></Link>
            <Link to='/history/2020-2021' id="historyLink"><li >2020-2021</li></Link>
            <Link to='/history/2021-2022' id="historyLink"><li >2021-2022</li></Link>
            <Link to='/history/2022-2023' id="historyLink"><li >2022-2023</li></Link>
            <Link to='/history/2023-2024' id="historyLink"><li >2023-2024</li></Link>
          </ul>
        </div>
        <Routes>
          <Route path='2006-2007' element={<History0607/>}/>
          <Route path='2007-2008' element={<History0708/>}/>
          <Route path='2008-2009' element={<History0809/>}/>
          <Route path='2009-2010' element={<History0910/>}/>
          <Route path='2010-2011' element={<History1011/>}/>
          <Route path='2011-2012' element={<History1112/>}/>
          <Route path='2012-2013' element={<History1213/>}/>
          <Route path='2013-2014' element={<History1314/>}/>
          <Route path='2014-2015' element={<History1415/>}/>
          <Route path='2015-2016' element={<History1516/>}/>
          <Route path='2016-2017' element={<History1617/>}/>
          <Route path='2017-2018' element={<History1718/>}/>
          <Route path='2018-2019' element={<History1819/>}/>
          <Route path='2019-2020' element={<History1920/>}/>
          <Route path='2020-2021' element={<History2021/>}/>
          <Route path='2021-2022' element={<History2122/>}/>
          <Route path='2022-2023' element={<History2223/>}/>
          <Route path='2023-2024' element={<History2324/>}/>
          <Route element={NotFound()}/>
        </Routes>
    </div>
  );
}
