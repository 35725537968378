import React from 'react';
import { Link } from 'react-router-dom';

export default function Confirmation(props) {
    return (
        <div>
            <p>Thanks for submitting feedback! We really appreciate it</p>
            <p>We'll cover it at the next eboard meeting (hopefully)</p>
            <Link
            to='/feedback'
            className={`${props.cssOff ? '' : 'secBarLink'}`}>
                Back to Feedback
            </Link>
        </div>
    );
}