import React from 'react';

export default function Mayonnaise() {
  return (
    <div>
      <iframe
        title='mayonnaise'
        width="1200"
        height="700"
        src="https://www.youtube.com/embed/71GWS3ccmvI?autoplay=1"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      >
      </iframe>
    </div>
  );
}
