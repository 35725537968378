import React from 'react';

export default function History1011() {
  return (
    <div className='History'>
      <h2>History 2010-2011</h2>
      <h2>E-Board:</h2>
        <p><b>President: </b>Mitch Quartner</p>
        <p><b>Vice President: </b>Chris Bentivenga</p>
        <p><b>Vice President: </b>Matt Fico</p>
        <p><b>Secretary: </b>Laura Osselmann</p>
        <p><b>Treasurer: </b>Erin Kiselica</p>
        <p><b>Conductor: </b>Derek Erdmann</p>
        <p><i>Conductor Attire:</i> Orange Vest, White Shirt, Black Pants, Black Bow Tie, Bare Hands, black Homburg (Hat)</p>

      <h3>GOD Status</h3>
        <p>Drew Zemke (Tuba): Fnugg</p>

      <h3>Graduating Members</h3>
        <p>Erin Miller (Tenor Saxophone)</p>
        <p>Hans Melhus (Trombone)</p>
        <p>Jaspreet Dabb (Percussion)</p>
        <p>Karen Smith (Trombone)</p>
        <p>Laura Osselmann (Clarinet)</p>

      <h3>New Music</h3>
        <p>25 or 6 to 4</p>
        <p>99 Red Balloons</p>
        <p>A Team</p>
        <p>All Start</p>
        <p>Beat It</p>
        <p>Brick House</p>
        <p>Can't Turn You Loose</p>
        <p>Carry On My Wayward Son</p>

      <h3>Accomplishments and Achievements</h3>
        <p>Played at all Men's and Women's Home Hockey Games</p>
        <p>Played at two Hockey Games at the Same Time</p>
        <p>Played at the Rochester Rhino's Game</p>
        <p>Played at a Basketball Game</p>
        <p>Played during a Nationally Televised Game</p>
        <p>Played at Spring Fest</p>
        <p>Played at Imagine RIT</p>
        <p>Travelled with Team to Cornell University</p>
        <p>Travelled with Team to Rensselaer Polytechnic Institute</p>
        <p>Designed and Sold New T-shirts and Sweatshirts</p>
        <p>Designed Senior Patches</p>
        <p>Recorded the Band</p>
        <p>Bake Sale Fundraiser</p>
        <p>Can Drive Fundraiser</p>
        <p>Jersey Raffle Fundraiser</p>
        <p>Re-worked the Constitution</p>
        <p>Voted Student Government Performance Arts Club of the Year</p>
        <p><b>Max Attendance at an Event: 82</b></p>
    </div>
  );
}
