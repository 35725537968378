import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App';

export default function Statistics(props) {
  const [users, setUsers] = useState([])
  const [events, setEvents] = useState([])
  const [totalEventAttendance, setTotalEventAttendance] = useState(0)
  const [largestEvent, setLargestEvent] = useState(null)
  const [largestRehearsal, setLargestRehearsal] = useState(null)
  const [highestFallR, setHighestFallR] = useState(null)
  const [highestFallE, setHighestFallE] = useState(null)
  const [highestSpringR, setHighestSpringE] = useState(null)
  const [highestSpringE, setHighestSpringR] = useState(null)

  useEffect(() => {
    const url = `${props.constants.hostname}/api/secretary/statistics`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setUsers(data.users)
        setEvents(data.events)
        setTotalEventAttendance(data.eventStats.totalEventAttendance.count)
        setHighestFallR(data.eventStats.highestFallR)
        setHighestFallE(data.eventStats.highestFallE)
        setHighestSpringR(data.eventStats.highestSpringR)
        setHighestSpringE(data.eventStats.highestSpringE)
        if (data.eventStats.highestFallE !== null) {
          if (data.eventStats.highestSpringE === null) {
            setLargestEvent(data.eventStats.highestFallE)
          }
          else if (data.eventStats.highestFallE.count > data.eventStats.highestSpringE.count) {
            setLargestEvent(data.eventStats.highestFallE)
          } 
          else {
            setLargestEvent(data.eventStats.highestSpringE)
          }
        }
        if (data.eventStats.highestFallR !== null) {
          if (data.eventStats.highestSpringR === null) {
            setLargestRehearsal(data.eventStats.highestFallR)
          }
          else if (data.eventStats.highestFallR.count > data.eventStats.highestSpringR.count) {
            setLargestRehearsal(data.eventStats.highestFallR)
          } else {
            setLargestRehearsal(data.eventStats.highestSpringR)
          }
        }
      })
      .catch((error) => { console.error(error); })
  }, [])

  function handleDownload(data, filename) {
    const csvContent = `data:text/csv;charset=utf-8,${data.map((e) => `${e.SECTION},${e.LastName},${e.FirstName},${e.DCE}`).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    const now = new Date();
    link.setAttribute('download', `${filename}-(${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}).csv`);
    document.body.appendChild(link);
    link.click();
  }

  function handleWomensAbleList() {
    const url = `${props.constants.hostname}/api/secretary/wgamego`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
      .then((res) => {
        if (!res.ok) {
          return false;
          }
          return res.json();
      })
      .then((data) => {
          if (!data) { return; }
          if (data.length === 0) {
            alert('No users able to go to womens games at this time')
          } else {
            handleDownload(data, 'W-Game-Eligible')
          }
      })
      .catch((error) => { console.error(error); });
  }

  function handleMensAbleList() {
    const url = `${props.constants.hostname}/api/secretary/mgamego`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
      .then((res) => {
        if (!res.ok) {
          return false;
          }
          return res.json();
      })
      .then((data) => {
          if (!data) { return; }
          if (data.length === 0) {
            alert('No users able to go to mens games at this time')
          } else {
            handleDownload(data, 'M-Game-Eligible')
          }
      })
      .catch((error) => { console.error(error); });
  }

  function handleGoodStanding() {
    const url = `${props.constants.hostname}/api/secretary/goodStanding`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
      .then((res) => {
        if (!res.ok) {
          return false;
          }
          return res.json();
      })
      .then((data) => {
          if (!data) { return; }
          if (data.length === 0) {
            alert('No users with good standing at this time')
          } else {
            handleDownload(data, 'Good-Standing')
          }
      })
      .catch((error) => { console.error(error); });
  }

  return (
    <div>
      <h3>Organization-wide Statistic Generators</h3>
      <hr />
      <div>
        <div>
          <h3>General Statistics:</h3>
          <p>Users in System: {users.length}</p>
          <p>Events in System: {events.length}</p>
          <p>Total Attendance For All Events: {totalEventAttendance}</p>
          <p>
            Highest Attended Event For Both Semesters: {largestEvent === null || largestEvent.count === 0 ? 'No Events Have Taken Place' : null}
          </p>
          {largestEvent === null || largestEvent.count === 0 ?
            null
            :
            <div className='tableDiv'>
              <table className='userTable'>
                <thead>
                  <tr className='userRow userHeaderRow'>
                    <th className='userHeader'>Name</th>
                    <th className='userHeader'>Type</th>
                    <th className='userHeader'>Date</th>
                    <th className='userHeader'>Number of Attendees</th>
                  </tr>
                </thead>
                <tbody>
                <tr key={largestEvent.Name} className='userRow' style={{cursor: 'auto'}}>
                  <td className='userEntrySeparate'>{largestEvent.Name}</td>
                  <td className='userEntrySeparate'>{largestEvent.Type}</td>
                  <td className='userEntrySeparate'>{largestEvent.DAY}</td>
                  <td className='userEntrySeparate'>{largestEvent.count}</td>
                </tr>
                </tbody>
              </table>
            </div>
          }
          <p>
            Highest Attended Rehearsal For Both Semesters: {largestRehearsal === null || largestRehearsal.count === 0 ? 'No Rehearsals Have Taken Place' : null}
          </p>
          {largestRehearsal === null || largestRehearsal.count === 0 ?
            null
            :
            <div className='tableDiv'>
              <table className='userTable'>
                <thead>
                  <tr className='userRow userHeaderRow'>
                    <th className='userHeader'>Name</th>
                    <th className='userHeader'>Type</th>
                    <th className='userHeader'>Date</th>
                    <th className='userHeader'>Number of Attendees</th>
                  </tr>
                </thead>
                <tbody>
                <tr key={largestRehearsal.Name} className='userRow' style={{cursor: 'auto'}}>
                  <td className='userEntrySeparate'>{largestRehearsal.Name}</td>
                  <td className='userEntrySeparate'>{largestRehearsal.Type}</td>
                  <td className='userEntrySeparate'>{largestRehearsal.DAY}</td>
                  <td className='userEntrySeparate'>{largestRehearsal.count}</td>
                </tr>
                </tbody>
              </table>
            </div>
          }
          <p>
            Highest Attended Event For Fall Semester: {highestFallE === null || highestFallE.count === 0 ? 'No Events Have Taken Place' : null}
          </p>
          {highestFallE === null || highestFallE.count === 0 ?
            null
            :
            <div className='tableDiv'>
              <table className='userTable'>
                <thead>
                  <tr className='userRow userHeaderRow'>
                    <th className='userHeader'>Name</th>
                    <th className='userHeader'>Type</th>
                    <th className='userHeader'>Date</th>
                    <th className='userHeader'>Number of Attendees</th>
                  </tr>
                </thead>
                <tbody>
                <tr key={highestFallE.Name} className='userRow' style={{cursor: 'auto'}}>
                  <td className='userEntrySeparate'>{highestFallE.Name}</td>
                  <td className='userEntrySeparate'>{highestFallE.Type}</td>
                  <td className='userEntrySeparate'>{highestFallE.DAY}</td>
                  <td className='userEntrySeparate'>{highestFallE.count}</td>
                </tr>
                </tbody>
              </table>
            </div>
          }
          <p>
            Highest Attended Event For Spring Semester: {highestSpringE === null || highestSpringE.count === 0 ? 'No Events Have Taken Place' : null}
          </p>
          {highestSpringE === null || highestSpringE.count === 0 ?
            null
            :
            <div className='tableDiv'>
              <table className='userTable'>
                <thead>
                  <tr className='userRow userHeaderRow'>
                    <th className='userHeader'>Name</th>
                    <th className='userHeader'>Type</th>
                    <th className='userHeader'>Date</th>
                    <th className='userHeader'>Number of Attendees</th>
                  </tr>
                </thead>
                <tbody>
                <tr key={highestSpringE.Name} className='userRow' style={{cursor: 'auto'}}>
                  <td className='userEntrySeparate'>{highestSpringE.Name}</td>
                  <td className='userEntrySeparate'>{highestSpringE.Type}</td>
                  <td className='userEntrySeparate'>{highestSpringE.DAY}</td>
                  <td className='userEntrySeparate'>{highestSpringE.count}</td>
                </tr>
                </tbody>
              </table>
            </div>
          }
          <p>
            Highest Attended Rehearsal For Fall Semester: {highestFallR === null || highestFallR.count === 0 ? 'No Rehearsals Have Taken Place' : null}
          </p>
          {highestFallR === null || highestFallR.count === 0 ?
            null
            :
            <div className='tableDiv'>
              <table className='userTable'>
                <thead>
                  <tr className='userRow userHeaderRow'>
                    <th className='userHeader'>Name</th>
                    <th className='userHeader'>Type</th>
                    <th className='userHeader'>Date</th>
                    <th className='userHeader'>Number of Attendees</th>
                  </tr>
                </thead>
                <tbody>
                <tr key={highestFallR.Name} className='userRow' style={{cursor: 'auto'}}>
                  <td className='userEntry'>{highestFallR.Name}</td>
                  <td className='userEntry'>{highestFallR.Type}</td>
                  <td className='userEntry'>{highestFallR.DAY}</td>
                  <td className='userEntry'>{highestFallR.count}</td>
                </tr>
                </tbody>
              </table>
            </div>
          }
          <p>
            Highest Attended Rehearsal For Spring Semester: {highestSpringR === null || highestSpringR.count === 0 ? 'No Rehearsals Have Taken Place' : null}
          </p>
          {highestSpringR === null || highestSpringR.count === 0 ?
            null
            :
            <div className='tableDiv'>
              <table className='userTable'>
                <thead>
                  <tr className='userRow userHeaderRow'>
                    <th className='userHeader'>Name</th>
                    <th className='userHeader'>Type</th>
                    <th className='userHeader'>Date</th>
                    <th className='userHeader'>Number of Attendees</th>
                  </tr>
                </thead>
                <tbody>
                <tr key={highestSpringR.Name} className='userRow' style={{cursor: 'auto'}}>
                  <td className='userEntrySeparate'>{highestSpringR.Name}</td>
                  <td className='userEntrySeparate'>{highestSpringR.Type}</td>
                  <td className='userEntrySeparate'>{highestSpringR.DAY}</td>
                  <td className='userEntrySeparate'>{highestSpringR.count}</td>
                </tr>
                </tbody>
              </table>
            </div>
          }
        </div>
        <div>
          <h3>Download Statistics:</h3>
          <div
            onClick={() => handleWomensAbleList()}
            className='oneline csvDownload'
          >
            <FontAwesomeIcon icon='file-download' className='fileDownload'/>
            <p>Download All Users Able to Attend a Women's Hockey Game</p>
          </div>
          <div
            onClick={() => handleMensAbleList()}
            className='oneline csvDownload'
          >
            <FontAwesomeIcon icon='file-download' className='fileDownload'/>
            <p>Download All Users Able to Attend a Men's Hockey Game</p>
          </div>
          <div
            onClick={() => handleGoodStanding()}
            className='oneline csvDownload'
          >
            <FontAwesomeIcon icon='file-download' className='fileDownload'/>
            <p>Download All Users With Good Standing</p>
          </div>
        </div>
      </div>
    </div>
  );
}