import React from 'react';

export default function Rickroll() {
  return (
    <div id="rickroll">
      <iframe
        title='rickroll'
        width="1200"
        height="700"
        src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      >
      </iframe>
    </div>
  );
}
