import React, { useState } from 'react';

export default function Ready(props) {
  const [stage, setStage] = useState(1)
  function handleClick() {
    setStage(stage + 1)
  }

  const first = (
    <div>
      <h1>ARE YOU READY KIDS?!?!?!</h1>
      <button onClick={handleClick} className={`${props.cssOff ? '' : 'spongeButton'}`}>
        AYE AYE CAPTAIN
      </button>
    </div>
  );

  const second = (
    <div>
      <h1>I CAN'T HEAR YOU!</h1>
      <button onClick={handleClick} className={`${props.cssOff ? '' : 'spongeButton'}`}>
        AYE AYE CAPTAIN!!!!!!!!!!!!!!!!
      </button>
    </div>
  );

  const third = (
    <div>
      <iframe
        title='sponge to the bob'
        width="1200"
        height="700"
        src="https://www.youtube.com/embed/r9L4AseD-aA?start=8&autoplay=1"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      >
      </iframe>
    </div>
  );

  return (
    <div>
    {stage === 1 ?
      first
      :
      stage === 2 ?
        second
        :
        stage === 3 ?
          third
          :
          null
    }
    </div>
  );
}
