import React from 'react';

export default function History0607() {
  return (
    <div className='History'>
      <h2>History 2006-2007</h2>
      <h2>E-Board:</h2>
        <p><b>President: </b>Stephanie Dorn</p>
        <p><b>Vice President: </b>Amanda Preske</p>
        <p><b>Secretary: </b>Tim George</p>
        <p><b>Treasurer: </b>Spencer Herendeen</p>

      <h3>Graduating Members</h3>
        <p>Ben Hughes</p>
        <p>Brian Garrison</p>

      <h3>New Music</h3>
        <p>Celebration</p>
        <p>Dragnet</p>
        <p>Eye of the Tiger</p>
        <p>Gonna Fly Now</p>
        <p>Gimme Some Lovin'</p>
        <p>Hawaii 5-0</p>
        <p>Hey Song</p>
        <p>In the Midnight Hour</p>
        <p>James Bond Theme</p>
        <p>Louie, Louie</p>
        <p>Peter Gunn</p>
        <p>Proud Mary</p>
        <p>Tequila</p>

      <h3>Accomplishments and Achievements</h3>
        <p>Became an official Student Government Club</p>
        <p>Played at Men's Hockey Game</p>
        <p>Traveled with the team to Canisius</p>
        <p>Made Website</p>
        <p>Made Home Jersey's our Uniform</p>
        <p>Played Eye of the Tiger after Goals</p>
        <p><b>Max Attendance at an Event: 20</b></p>
    </div>
  );
}
