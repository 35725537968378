import React, { useEffect, useState } from 'react';
import { authRole } from '../../..';
import { USERID } from '../../App';
import { Link } from 'react-router-dom';
import '../../../css/voting.css';

export default function Home(props) {
  const [availableElections, setAvailable] = useState([])
  
  useEffect(() => {
    const url = `${props.constants.hostname}/api/voting/availableElections`
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then((data) => {
      if (!data) { return; }
      setAvailable(data)
    })
    .catch((error) => { console.error(error); });
  }, [])

  function convertElectionTR(election) {
    return (
      <tr key={election.ElectionName}>
        <td style={{textDecoration: 'underline', color: 'blue', width: '5%'}}>
        <Link to={`/vote/election/${election.ElectionName}`}>{election.ElectionName}</Link></td>
        <td>{election.EndDate}</td>
      </tr>
    )
  }
  
  const roles = props.constants.roles;
  return (
      <div>
          {authRole(props.userRole, roles.president) ?
          <div>
            <Link
              to='/vote/create'
              className='secBarLink'
            >
              <div className='oneline'>
                <p className='secBarText'>
                  Create Election
                </p>
              </div>
              <br/>
            </Link>
            <Link
              to='/vote/results'
              className='secBarLink'
            >
              <div className='oneline'>
                <p className='secBarText'>
                  View Election Results
                </p>
              </div>
              <br/>
            </Link>
          </div>
          :
          null
        }
        <br/>
        <h2>Elections: </h2>
        <table>
          <tr>
            <th style={{width: '5%'}}>Title</th>
            <th style={{width: '5%'}}>End Date</th>
          </tr>
          {availableElections.map((election) => convertElectionTR(election))}
        </table>
    </div>
  )
}