import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './QdbPages/Home';
import AddQuote from './QdbPages/AddQuote';
import ApproveQuote from './QdbPages/ApproveQuote';

export default function Qdb(props) {
  return (
    <div id='Qdb'>
      <Routes>
        <Route index element={<Navigate to="home" replace/>} />
        <Route path='home' element={<Home constants={props.constants} userRole={props.userRole}/>}/>
        <Route path='addQuote' element={<AddQuote constants={props.constants}/>}/>
        <Route path='approveQuote' element={<ApproveQuote constants={props.constants} userRole={props.userRole}/>}/>
      </Routes>
    </div>
  );
}
