import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { USERID } from '../../App';
import { useNavigate, useParams } from 'react-router-dom';

export default function Event(props) {
  const params = useParams()
  const [name, setName] = useState('')
  const [date, setDate] = useState('')
  const [type, setType] = useState('')
  const [eid, setEID] = useState(params.eventID)
  const [rehearsal, setRehearsal] = useState('')
  const [eventEdit, setEventEdit] = useState(false)
  const [remove, setRemove] = useState(false)
  const [attendees, setAttendees] = useState([])
  const navigate = useNavigate()

  function handleDownload() {
    const csvContent = `data:text/csv;charset=utf-8,${attendees.map((e) => `${e.SECTION},${e.LastName},${e.FirstName}`).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    const now = new Date();
    link.setAttribute('download', `Attendance-${type}-${date}-(${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}).csv`);
    document.body.appendChild(link);
    link.click();
  }

  function confirm() {
    if (window.confirm('Delete this event?')) {
      const url = `${props.constants.hostname}/api/secretary/removeEvent?eventInfo=${eid}`;
      fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`},
        method: 'DELETE',
      })
      .then((res) => {
        setRemove(true)
        if (!res.okay) {
          return false;
        }
        return res.json();
      })
      .catch((e) => { console.error(e); });
    }
  }

  useEffect(() => {
    if (params.eventID != null && params.eventID !== undefined) {
      const url = `${props.constants.hostname}/api/secretary/event?editEvent=${eid}`;
      fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
      })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setName(data[0].Name)
        setDate(data[0].DAY)
        setType(data[0].Type)
        setEID(data[0].Eid)
        setRehearsal(data[0].Rehearsal)
      })
      .catch((error) => { console.error(error); })
    }
    const url = `${props.constants.hostname}/api/secretary/eventAttendance?eventID=${eid}`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      else {
        return res.json();
      }
    })
    .then((data) => {
      setAttendees(data)
    })
    .catch((e) => { console.error(e); })
  }, [])

  function generateRow(user) {
    return (
      <tr key={user.DCE} className='userRow' style={{cursor: 'auto'}}>
        <td className='userEntry'></td>
        <td className='userEntry'>{user.LastName}</td>
        <td className='userEntry'>{user.FirstName}</td>
      </tr>
    );
  }

  if(eventEdit) {
    navigate(`/secretary/eventEdit/${eid}`)
  }
  if(remove) {
    navigate('/secretary/events')
  }

  return (
    <div>
      <h3>Event Information for {name}</h3>
      <div className='secBarNav'>
        <FontAwesomeIcon
          icon='user-edit'
          className='faEdit'
          onClick={() => setEventEdit(true)}
        />
        <FontAwesomeIcon
          icon='file-download'
          className='faEdit'
          style={{marginLeft: '0px'}}
          onClick={() => handleDownload()}
        />
        <FontAwesomeIcon
          icon='trash-alt'
          className='faDelete'
          onClick={() => confirm()}
        />
      </div>
      <hr />
      <div className='userProfile'>
        <label className='secretaryLabel'>
          Name: {name}
        </label>
        <label className='secretaryLabel'>
          Date: {date}
        </label>
        <label className='secretaryLabel'>
          Type: {type}
        </label>
        <label className='secretaryLabel'>
          Count as Rehearsal? {rehearsal === 'Y' ? 'Yes' : 'No'}
        </label>
      </div>
      <h4>Individuals In Attendance ({attendees.length}):</h4>
      <div className='tableDiv'>
        <table className='userTable'>
          <thead>
            <tr className='userRow userHeaderRow'>
              <th className='userHeader'>Section</th>
              <th className='userHeader'>Last Name</th>
              <th className='userHeader'>First Name</th>
            </tr>
          </thead>
          <tbody>
            {attendees.map((user) => generateRow(user))}
          </tbody>
        </table>
      </div>
    </div>
  );
}