import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../css/conductor.css'
import { USERID } from '../App';
import { authRole } from '../..';
import { Link, useNavigate } from 'react-router-dom';

export default function Conductor(props) {
  const navigate = useNavigate()
  if(authRole(props.userRole, props.constants.roles.Conductor)) {
    navigate('404')
  }
  const [songs, setSongs] = useState([])
  const [filtered, setFiltered] = useState([])

  useEffect(() => {
    const url = `${props.constants.hostname}/api/music`;
    fetch(url, {
      headers: { Authorization: `${localStorage.getItem(USERID)}` }
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) {
          return;
        }
        setSongs(data)
        setFiltered(data)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [])

  function handleSearch(event) {
    const filteredSongs = [];
    let idx = 0;
    const searchVal = event.target.value.toLowerCase();
    songs.filter((song) => {
      if (song.Title.toLowerCase().includes(searchVal)) {
        filteredSongs[idx] = song;
        idx++;
      }
      return filteredSongs;
    })
    setFiltered(filteredSongs)
  }

  function statusAll(status) {
    const url = `${props.constants.hostname}/api/music/statusAll`;
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem(USERID)}`
      },
      body: JSON.stringify({ status: status })
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        return res.text();
      })
      .catch((error) => {
        console.error(error);
      });
    const updatedSongs = [...songs];
    updatedSongs.forEach((item) => {
      item.Status = status;
    });
    setSongs(updatedSongs)
    alert(`All songs set to ${status}`);
  }

  return (
    <div id='conductor'>
      <h2 className={`${props.cssOff ? '' : 'conductorTitle'}`}>Conductor</h2>
      <div className={`${props.cssOff ? '' : 'conductorContent'}`}>
        <div className={`${props.cssOff ? '' : 'statusAllHeader'}`}>
          <input type='button' className={`${props.cssOff ? '' : 'songStatusButton statusAll allPublic'}`} value='All Public'
            onClick={() => {
              if (window.confirm('Are you sure you want to make all songs public?')) {
                statusAll('Public');
              }
            }} />
          <input
            onChange={(e) => handleSearch(e)}
            className={`${props.cssOff ? '' : 'songSearch'}`}
            placeholder='Search for songs...'
          />
          <input type='button' className={`${props.cssOff ? '' : 'songStatusButton statusAll allPrivate'}`} value='All Private'
            onClick={() => {
              if (window.confirm('Are you sure you want to make all songs private?')) {
                statusAll('Private');
              }
            }} />
        </div>
        <table className={`${props.cssOff ? '' : 'conductorTable'}`}>
          <thead>
            <tr className={`${props.cssOff ? '' : 'conductorRow'}`}>
              <th className={`${props.cssOff ? '' : 'conductorHeader'}`}>Song</th>
              <th className={`${props.cssOff ? '' : 'conductorHeader'}`}>mp3</th>
              <th className={`${props.cssOff ? '' : 'conductorHeader'}`}>Status</th>
              <th className={`${props.cssOff ? '' : 'conductorHeader'}`}>Public Comments</th>
              <th className={`${props.cssOff ? '' : 'conductorHeader'}`}>Private Comments</th>
            </tr>
          </thead>
          <tbody>
            {
              filtered.map((song) =>
                <ConductorEntry key={song.Slug} constants={props.constants} song={song} cssOff={props.cssOff} />
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

function ConductorEntry(props) {
  const [song, setSong] = useState(props.song)
  const [initPubComment] = useState(song.PublicComment)
  const [initPriComment] = useState(song.PrivateComment)
  const [needPubSave, setNeedPubSave] = useState(false)
  const [needPriSave, setNeedPriSave] = useState(false)

  function pubCommChange(event) {
    const tempSong = JSON.parse(JSON.stringify(song))
    tempSong.PublicComment = event.target.value;
    if (initPubComment !== tempSong.PublicComment) {
      setSong(tempSong)
      setNeedPubSave(true)
    } else {
      setSong(tempSong)
      setNeedPubSave(false)
    }
  }

  function priCommChange(event) {
    const tempSong = JSON.parse(JSON.stringify(song));
    tempSong.PrivateComment = event.target.value;
    if (initPriComment !== tempSong.PrivateComment) {
      setSong(tempSong)
      setNeedPriSave(true)
    } else {
      setSong(tempSong)
      setNeedPriSave(false)
    }
  }

  function savePubCom() {
    const url = `${props.constants.hostname}/api/music/applyPublicComment`;
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem(USERID)}`
      },
      body: JSON.stringify({ song: song })
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        setNeedPubSave(false)
        return true
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function savePriCom() {
    const url = `${props.constants.hostname}/api/music/applyPrivateComment`;
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem(USERID)}`
      },
      body: JSON.stringify({ song: song })
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        setNeedPriSave(false)
        return true
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function toggleStatus() {
    const url = `${props.constants.hostname}/api/music/toggleStatus`;
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem(USERID)}`
      },
      body: JSON.stringify({ song: song })
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        return res.text();
      })
      .then((data) => {
        if (!data) {
          return;
        }
        const changedSong = { ...song };
        changedSong.Status = data;
        setSong(changedSong)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <tr className={`${props.cssOff ? '' : 'conductorRow'}`}>
      <td className={`${props.cssOff ? '' : 'conductorData'}`}>
        <div key={song.Slug} className={`${props.cssOff ? '' : 'songLink'}`}>
          <Link
            to={`/music/${song.Slug}`}
            className={`${props.cssOff ? '' : 'songColor'}`}
          >
            <FontAwesomeIcon icon='file-alt' className={`${props.cssOff ? '' : 'faFile'}`} />
            {song.Slug}
          </Link>
        </div>
      </td>

      <td className={`${props.cssOff ? '' : 'conductorData'}`}>
        {song.mp3 ?
          <audio controls>
            <source
              src={`${props.constants.hostname}/api/music/mp3?title=${song.Title}&user=${localStorage.getItem(USERID)}`}
              type='audio/mpeg'
            ></source>
            Your browser does not support the audio element.
          </audio>
          :
          <p>None</p>
        }

      </td>
      <td className={`${props.cssOff ? '' : 'conductorData'}`}>
        <input type='button' className={`${props.cssOff ? '' : `songStatusButton status${song.Status}`}`} value={song.Status} onClick={toggleStatus} />
      </td>
      <td className={`${props.cssOff ? '' : 'conductorData'}`}>
        <div className={`${props.cssOff ? '' : 'oneline commentSave'}`}>
          <input
            type='text'
            className={`${props.cssOff ? '' : 'conductorInput'}`}
            onChange={(e) => pubCommChange(e)}
            value={song.PublicComment}
            placeholder='Jimmy a comment in here..'
            maxLength='500'
          />
          {needPubSave ?
            <FontAwesomeIcon
              icon='save'
              className={`${props.cssOff ? '' : 'faSave'}`}
              onClick={savePubCom}
            />
            :
            null
          }
        </div>
      </td>
      <td className={`${props.cssOff ? '' : 'conductorData'}`}>
        <div className={`${props.cssOff ? '' : 'oneline commentSave'}`}>
          <input
            type='text'
            className={`${props.cssOff ? '' : 'conductorInput'}`}
            onChange={(e) => priCommChange(e)}
            value={song.PrivateComment}
            placeholder='Jimmie a comment in here..'
            maxLength='500'
          />
          {needPriSave ?
            <FontAwesomeIcon
              icon='save'
              className={`${props.cssOff ? '' : 'faSave'}`}
              onClick={savePriCom}
            />
            :
            null
          }
        </div>
      </td>
    </tr>
  );
}
