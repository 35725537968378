import React from 'react';
import '../../../css/history.css'

export default function History2021() {
  return (
    <div className='History'>
      <h2>History 2020-2021</h2>
      <h2>E-Board:</h2>
        <p><b>President: </b>Emma Lubes</p>
        <p><b>Vice President: </b>Meg Johnson</p>
        <p><b>Secretary: </b>Kristen Patten</p>
        <p><b>Fall Treasurer: </b>Nathan Fritz</p>
        <p><b>Spring Treasurer: </b>Rachel Milcic</p>
        <p><b>Conductor: </b>James Edward Harkins IV</p>
        <p><i>Conductor Attire: </i>Unwashed t-shirt, sweatpants, mask, face shield, bandolier of hand sanitizer, Black Tie</p>

      <h3>Accomplishments and Achievements</h3>
        <p>Survived Covid-19</p>
          <p>&emsp; - Transitioned to remote band</p>
          <p>&emsp; - Restructured Good Standing Calculations to account for Covid Restrictions</p>
        <p>President Munson requested us to perform at the naming ceremony of the SHED</p>
        <p>Constitutional Amendments: </p>
          <p>&emsp; - Webmaster's Responsibilites clarified</p>
          <p>&emsp; - Mass Grammar Correction</p>
        <p><b>Max Attendance at an Event: </b></p>
    </div>
  );
}
