import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import ViewFeedback from './FeedbackPages/ViewFeedback';
import SubmitFeedback from './FeedbackPages/SubmitFeedback';
import Confirmation from './FeedbackPages/Confirmation';

export default function Feedback(props) {
  return (
    <div id="Feedback">
      <Routes>
        <Route index element={<Navigate to='submit' replace/>} />
        <Route path='submit' element={<SubmitFeedback constants={props.constants} userRole={props.userRole} cssOff={props.cssOff}/>}/>
        <Route path='confirm' element={<Confirmation cssOff={props.cssOff}/>}/>
        <Route path='view' element={<ViewFeedback constants={props.constants} userRole={props.userRole} cssOff={props.cssOff}/>}/>
      </Routes>
    </div>
  );
}
