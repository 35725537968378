import React, { useEffect, useState } from 'react';
import '../../css/president.css';
import { nameCheck, cellCheck, emailCheck } from '../Errors';
import { USERID } from '../App';
import { useNavigate } from 'react-router-dom';
import { authRole } from '../..';

export default function President(props) {
  const navigate = useNavigate()
  if(authRole(props.userRole, props.constants.roles.president)) {
    navigate('404')
  }

  const [eboard, setEboard] = useState([])
  const [sLeads, setSleads] = useState([])
  const [boolShowModal, setBoolShowModal] = useState(false)
  const [isPrim, setIsPrim] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [section, setSection] = useState('')
  const [cell, setCell] = useState('')
  const [email, setEmail] = useState('')
  const [slug, setSlug] = useState('')

  function loadFullEboard() {
    const url = `${props.constants.hostname}/api/fullEboard`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then((data) => {
      if (!data) { return; }
      setEboard(data.eboard)
      setSleads(data.sectionLeaders)
    })
    .catch((error) => { console.error(error); });
  }

  useEffect(loadFullEboard, [])

  function showModal(isPrimary, editingMember) {
    setBoolShowModal(true)
    setIsPrim(isPrimary)
    setFirstName(editingMember.FirstName)
    setLastName(editingMember.LastName)
    setSection(editingMember.Section)
    setCell(editingMember.Cell)
    setEmail(editingMember.Email)
    setSlug(editingMember.slug)
  }

  function hideModal() {
    setBoolShowModal(false)
  }

  function handleClose(event) {
    if (event.target.className.includes('closeModal')) {
        hideModal();
    }
  }

  function errorCheck() {
    if (nameCheck(firstName)) {
      console.error(`Invalid First Name: ${firstName}`);
      alert(`Invalid First Name: ${firstName}\nPlease fix input`);
      return false;
    }
    if (nameCheck(lastName)) {
      console.error(`Invalid Last Name: ${lastName}`);
      alert(`Invalid Last Name: ${lastName}\nPlease fix input`);
      return false;
    }
    if (cellCheck(cell)) {
      console.error(`Invalid Cell: ${cell}`);
      alert(`Invalid Cell: ${cell}\nPlease fix input`);
      return false;
    }
    if (emailCheck(email)) {
      console.error(`Invalid Email: ${email}`);
      alert(`Invalid Email: ${email}\nPlease fix input`);
      return false;
    }
    if (email.includes('@g.rit.edu')) {
      setEmail(`${email.split('@')[0]}@rit.edu`)
    }
    return true;
  }

  function updateMember() {
    const editingMember = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Cell: cell,
    };

    let url = `${props.constants.hostname}/api/`;
    if (isPrim) {
      editingMember.Slug = slug;
      url += 'eboard/';
    }
    else {
      editingMember.Section = section;
      url += 'sectionLeaders/';
    }

    fetch(url, { 
        method: 'PUT',
        headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem(USERID)}`},
      body: JSON.stringify({ editingMember })
      })
    .then((res) => {
      if (!res.ok) {
        alert('Error occured when trying to update.\nIf persists, contact the webmaster.');
        return false;
      }
      else {
        loadFullEboard();
        alert('Successfully updated.');
        return true;
      }
    })
    .catch((error) => { console.error(error); })
  }
  
  function handleSave() {
    if(!errorCheck()) {
        return;
    }

    updateMember();
    handleClose({target: {className: 'closeModal'}});
  }

  if(!(eboard)) { return null }
  return (
    <div id='president'>
      <h2>President</h2>
      <h3>Eboard</h3>
      <table className={`${props.cssOff ? '' : 'president'}`}>
        <thead>
          <tr>
            <th>Position</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Cell</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {eboard.map((element) =>
            <tr key={element.Slug} className={`${props.cssOff ? '' : 'presidentRow'}`} onClick={() => showModal(true, element)}>
              <td>{element.Position}</td>
              <td>{element.FirstName}</td>
              <td>{element.LastName}</td>
              <td>{element.Cell}</td>
              <td>{element.Email}</td>
            </tr>
          )}
        </tbody>
      </table>
      <h3>Section Leaders</h3>
      <table className={`${props.cssOff ? '' : 'president'}`}>
        <thead>
          <tr>
            <th>Section</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Cell</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
            {sLeads.map((element) =>
              <tr key={element.Section} className={`${props.cssOff ? '' : 'presidentRow'}`} onClick={() => showModal(false, element)}>
                <td>{element.Section}</td>
                <td>{element.FirstName}</td>
                <td>{element.LastName}</td>
                <td>{element.Cell}</td>
                <td>{element.Email}</td>
              </tr>
            )}
        </tbody>
      </table>

      <div className={`${props.cssOff ? '' : `closeModal modal ${boolShowModal ? 'display-block' : 'display-none'}`}`} onClick={handleClose}>
        <div className={`${props.cssOff ? '' : 'modal-main'}`} onClick={null}>
          <div className={`${props.cssOff ? '' : 'modal-header'}`}>
            <span className={`${props.cssOff ? '' : 'modalX closeModal'}`}>&times;</span>
            <p className={`${props.cssOff ? '' : 'modal-title'}`}>Editing {isPrim ? slug : section}</p>
          </div>
          <div className={`${props.cssOff ? '' : 'modal-body'}`}>
              <label className={`${props.cssOff ? '' : 'presidentLabel'}`}>
                First Name:
                <input
                  type='text'
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  className={`${props.cssOff ? '' : 'presidentInput'}`}
                  maxLength='50'
                />
              </label>
              <br />
              <label className={`${props.cssOff ? '' : 'presidentLabel'}`}>
                Last Name:
                <input
                  type='text'
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  className={`${props.cssOff ? '' : 'presidentInput'}`}
                  maxLength='50'
              />
              </label>
              <br />
              <label className={`${props.cssOff ? '' : 'presidentLabel'}`}>
                Cell:
                <input
                  type='text'
                  onChange={(e) => setCell(e.target.value)}
                  value={cell}
                  className={`${props.cssOff ? '' : 'presidentInput'}`}
                  maxLength='10'
                />
              </label>
              <br />
              <label className={`${props.cssOff ? '' : 'presidentLabel'}`}>
                Email:
                <input
                  type='text'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className={`${props.cssOff ? '' : 'presidentInput'}`}
                  maxLength='15'
                />
              </label>
          </div>
          <div className={`${props.cssOff ? '' : 'modal-footer'}`}>
            <p className={`${props.cssOff ? '' : 'presidentWarning'}`}>*Updates will be effective immediately*</p>
            <button onClick={() => handleClose()} className={`${props.cssOff ? '' : 'closeModal btn-cancel modal-btn'}`}>Cancel</button>
            <button onClick={() => handleSave()} className={`${props.cssOff ? '' : 'btn-save modal-btn'}`}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
}
