import React from 'react';

export default function Footer(props) {
  if(props.noCSS) {
    return (
      <div>
        <p>Updated for the 2024-2025 Academic Year</p>
        <p>For Use by the RIT Pep Band Only</p>
      </div>
    );
  }
  return (
    <div className={`${props.cssOff ? '' : 'footer'}`}>
      <p className={`${props.cssOff ? '' : 'center footText'}`}>Updated for the 2024-2025 Academic Year</p>
      <p className={`${props.cssOff ? '' : 'center footText'}`}>For Use by the RIT Pep Band Only</p>
    </div>
  );
}
