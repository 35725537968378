import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { fireauth } from '../index';
import '../css/login.css';

function signInGoogle() {
    const provider = new GoogleAuthProvider();
    // eslint-disable-next-line
    signInWithPopup(fireauth, provider).catch(function (error) {
        console.error(`Error ${error.code}: ${error.message}`);
    });
}

export default function Login(props) {
  return (
    <div id='login' className='login'>
      <div className='signin'>
        <p className='t1 b signinText'>RIT Pep Band</p>
        <p className='t3 signinText'>Welcome to the RIT Pep Band website</p>
        <p className='t3 signinText'>
          Serving "The best Pep Band in the land" for all your musical needs.
        </p>
        {props.state.classic ?
          <div>
            <p className='signinText'>{props.message}</p>
            <div className='center'>
              <button onClick={signInGoogle} className='signinButton'>
                <img className='sigininImage' src="/media/google.png"
                  alt="Google Login"/>
              </button>
            </div>
            <p className='optionLogin' onClick={props.switchClassic}>
              Or Click here to sign in with a username and password (Non-RIT Students)
            </p>
          </div>
          :
          <div>
            {props.state.failed ?
              <p className='signinText'>Incorrect username or password</p>
              :
              <p className='signinText'>Please sign in</p>
            }
            <div className='center'>
              <form className='backupForm'>
                <label className='loginLabel'>
                  Username:
                  <input
                    type="text"
                    name="username"
                    onChange={props.userChange}
                    value={props.state.username}
                    className="loginInput"
                  />
                </label>
                <label className='loginLabel'>
                  Password:
                  <input
                    type="password"
                    name="password"
                    onChange={props.passChange}
                    value={props.state.password}
                    className="loginInput"
                  />
                </label>
                <div className='center'>
                  <button
                    onClick={props.handleLogin}
                    className='loginSubmit'
                  >
                    Submit
                  </button>
                </div>
                <p className='optionLogin' onClick={props.switchClassic}>
                  Or Click here to sign in with Google (RIT Students)
                </p>
              </form>
            </div>
          </div>
        }
      </div>
    </div>
  );
}
