import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { blankCheck } from '../../Errors';
import { USERID } from '../../App';
import { authRole } from '../../../index';

export default function SubmitFeedback(props) {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [error, setError] = useState(false)
  const [submit, setSubmit] = useState(false)
  const navigate = useNavigate()

  function errorCheck() {
    if (blankCheck(title)) {
      console.error('Blank field: Title')
      alert('Blank field: Title')
      return false;
    }
    if(blankCheck(content)) {
      console.error('Blank field: Content')
      alert('Blank field: Content')
      return false;
    }
    return true;
  }

  function getDate() {
    const date = new Date();
    return `${date.getFullYear()}-${date.getMonth() + 1}-${(`0${date.getDate()}`).slice(-2)}`
  }

  function submitData() {
    if(errorCheck()) {
      setError(false)
      const feedback = {
        Title: title,
        Date: getDate(),
        Content: content,
      }
      const url = `${props.constants.hostname}/api/feedback/addFeedback`
      fetch(url, {
        body: JSON.stringify({ feedback: feedback }),
        headers: {
          Authorization: `${localStorage.getItem(USERID)}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then((res) => {
        if (!res.ok) {
          this.setState({Error: true})
          return false;
        }
        else {
          setSubmit(true)
          return true
        }
      })
      .catch((e) => { console.error(e); })
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    submitData()
  }

  if(submit) {
    navigate('/feedback/confirm')
  }

  return (
    <div>
      {error ?
        <h2>The following elements could not be added. Contact the webmaster immediately</h2>
        :
        null
      }
      <h2>Submit Eboard Feedback</h2>
      <div className={`${props.cssOff ? '' : 'secBarNav'}`}>
        {authRole(props.userRole, props.constants.roles.president)
          || authRole(props.userRole, props.constants.roles.secretary)
          || authRole(props.userRole, props.constants.roles.conductor)
          || authRole(props.userRole, props.constants.roles.vicePresident)
          || authRole(props.userRole, props.constants.roles.treasurer)
          || authRole(props.userRole, props.constants.roles.sectionLeader) ?
          <Link to='/feedback/view' className={`${props.cssOff ? '' : 'secBarLink'}`}>
              <div className={`${props.cssOff ? '' : 'oneline'}`}>
                <p className={`${props.cssOff ? '' : 'secBarText'}`}>
                    View Feedback
                </p>
              </div>
              <br/>
          </Link>
          :
          null
        }
      </div>
      <hr />
      <p>Feel free to submit any feedback you have for the Eboard!</p>
      <p>This form is completely anonymous, so feel free to speak your mind</p>
      <p>It can be things we can improve on, or things you want to see more of</p>
      <p>Please only submit serious feedback, we take everything submitted here very seriously</p>
      <form className={`${props.cssOff ? '' : 'userForm'}`}>
        <table>
          <tbody>
            <tr>
              <td>
                <label className={`${props.cssOff ? '' : 'secretaryLabel'}`}>
                  Title:
                </label>
              </td>
              <td>
                <input
                  type='text'
                  name='Title'
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  className={`${props.cssOff ? '' : 'qdbInput'}`}
                  maxLength='100'
                />
              </td>
            </tr>
            <tr>
              <td>
                <label className={`${props.cssOff ? '' : 'secretaryLabel'}`}>
                  Feedback:
                </label>
              </td>
              <td>
                <textarea
                  type='text'
                  name='FirstName'
                  onChange={(e) => setContent(e.target.value)}
                  value={content}
                  className={`${props.cssOff ? '' : 'quoteTextArea'}`}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <button onClick={(e) => handleSubmit(e)} className={`${props.cssOff ? '' : 'secretarySubmit'}`}>
        Submit Feedback
      </button>
    </div>
  );
}
