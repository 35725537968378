import React from 'react';

export default function History1314() {
  return (
    <div className='History'>
      <h2>History 2013-2014</h2>
      <h2>E-Board:</h2>
        <p><b>President: </b>Teryn Rynone</p>
        <p><b>Vice President: </b>Sara Kearns</p>
        <p><b>Vice President: </b>Mike Ploch</p>
        <p><b>Secretary: </b>Kaity Moranz</p>
        <p><b>Treasurer: </b>Neil Guertin</p>
        <p><b>Conductor: </b>Andrew Hitchner</p>
        <p><i>Conductor Attire:</i> orange shirt(white black and orange plaid shirt alternate), grey pants, grey tie, grey fedora</p>

        <h3>Accomplishments and Achievements</h3>
          <p>Frozen Frontier and I mean Frozen (-4 fahrenheit without wind and chill)</p>
          <p>Last year in Ritter</p>
          <p>Played at the Rochester Lancers Games</p>
          <p>Pep Band Corner Crew Appreciation Dinner</p>
          <p><b>Max Attendance at an Event: ??</b></p>
    </div>
  );
}
