import React from 'react';

export default function Loading(props) {
  return (
    <div>
      <h1 className={`${props.noCSS ? '' : 'center'}`}>Easy there, tiger!</h1>
      <h2 className={`${props.noCSS ? '' : 'center'}`}>Gotta take a moment to thonk about that one</h2>
      <div className={`${props.noCSS ? '' : 'center'}`}>
        <img className={`${props.noCSS ? '' : 'thonkTiger rotate'}`} src='/media/thonkingtiger.png'
          alt="Thonk tiger is thinking..."/>
        <img className={`${props.noCSS ? '' : 'thonkTiger rotate'}`} src='/media/thonkingtiger.png'
          alt="Thonk tiger is thinking..."/>
        <img className={`${props.noCSS ? '' : 'thonkTiger rotate'}`} src='/media/thonkingtiger.png'
          alt="Thonk tiger is thinking..."/>
      </div>
      <h4 className={`${props.noCSS ? '' : 'center'}`}>
        If this problem persists, refresh the page or contact the admin
      </h4>
    </div>
  )
}
