import React, { useEffect, useState } from 'react';
import { USERID } from '../App';
import '../../css/eboard.css';

export default function EBoard(props) {
  const [eboard, setEboard] = useState([])
  const [sLeads, setSleads] = useState([])

  useEffect(() => {
    let url = `${props.constants.hostname}/api/eboard`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        return res.json();
      })
      .then((data) => {
        setEboard(data)
      })
      .catch((error) => { console.error(error); });

    url = `${props.constants.hostname}/api/sectionLeaders`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        return res.json();
      })
      .then((data) => {
        setSleads(data)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [])

  return (
    <div id='Eboard'>
      <table>
        <tr>
          <th>Eboard</th>
          <th>Section Leaders</th>
        </tr>
        <tr>
          <td className={`${props.cssOff ? '' : 'alignTop'}`}>
          <table className={`${props.cssOff ? '' : 'eboard'}`}>
            <thead>
              <tr className={`${props.cssOff ? '' : 'eboard'}`}>
                <th className={`${props.cssOff ? '' : 'eboard'}`}></th>
                <th className={`${props.cssOff ? '' : 'eboard'}`}>Position</th>
                <th className={`${props.cssOff ? '' : 'eboard'}`}>Name</th>
                <th className={`${props.cssOff ? '' : 'eboard'}`}>Cell</th>
                <th className={`${props.cssOff ? '' : 'eboard'}`}>Email</th>
              </tr>
            </thead>
            <tbody>
            {eboard.map((person) =>
              <tr key={person.Email}>
                <td>
                  <img
                    src={`/media/eboard_pics/${person.Slug}.jpg`}
                    alt={person.Position}
                    className={`${props.cssOff ? '' : 'eboardImage'}`}
                  >
                  </img>
                </td>
                <td className={`${props.cssOff ? '' : 'eboard'}`}>{person.Position}</td>
                <td className={`${props.cssOff ? '' : 'eboard'}`}>{person.FirstName} {person.LastName}</td>
                <td className={`${props.cssOff ? '' : 'eboard'}`}><a href={`tel:${person.Cell}`}>{person.Cell}</a></td>
                <td className={`${props.cssOff ? '' : 'eboard'}`}><a href={`mailto:${person.Email}`}>{person.Email}</a></td>
              </tr>
            )}
            </tbody>
          </table>
          </td>
          <td className={`${props.cssOff ? '' : 'alignTop'}`}>
            <table className={`${props.cssOff ? '' : 'eboard'}`}>
            <thead>
              <tr className={`${props.cssOff ? '' : 'eboard'}`}>
                <th className={`${props.cssOff ? '' : 'eboard'}`}></th>
                <th className={`${props.cssOff ? '' : 'eboard'}`}>Section</th>
                <th className={`${props.cssOff ? '' : 'eboard'}`}>Name</th>
                <th className={`${props.cssOff ? '' : 'eboard'}`}>Cell</th>
                <th className={`${props.cssOff ? '' : 'eboard'}`}>Email</th>
              </tr>
            </thead>
            <tbody>
            {sLeads.map((person) =>
              <tr key={person.Email}>
                <td>
                  <img
                    src={`/media/eboard_pics/${person.Section}.jpg`}
                    alt={person.Section}
                    className={`${props.cssOff ? '' : 'eboardImage'}`}
                  >
                  </img>
                </td>
                <td className={`${props.cssOff ? '' : 'eboard'}`}>{person.Section}</td>
                <td className={`${props.cssOff ? '' : 'eboard'}`}>{person.FirstName} {person.LastName}</td>
                <td className={`${props.cssOff ? '' : 'eboard'}`}><a href={`tel:${person.Cell}`}>{person.Cell}</a></td>
                <td className={`${props.cssOff ? '' : 'eboard'}`}><a href={`mailto:${person.Email}`}>{person.Email}</a></td>
              </tr>
            )}
            </tbody>
          </table>
          </td>
        </tr>
      </table>
    </div>
  );
}
