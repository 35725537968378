import React from 'react';
import '../../../css/history.css'

export default function History2122() {
  return (
    <div className='History'>
      <h2>History 2021-2022</h2>
      <h2>E-Board:</h2>
        <p><b>President: </b>Rachel Milcic</p>
        <p><b>Vice President: </b>Emma Lubes</p>
        <p><b>Secretary: </b>Melissa Kelleher</p>
        <p><b>Fall Treasurer: </b>Josh Randall</p>
        <p><b>Spring Treasurer: </b>Patrick Philippy</p>
        <p><b>Conductor: </b>Destini Schmit</p>
        <p><i>Conductor Attire: </i>Black pantsuit, orange button up, eyeliner, pink or green hair</p>

      <h3>Accomplishments and Achievements</h3>
        <p>Onboarded two years' worth of newcomers (due to covid)</p>
        <p>Survived the Ammonia Incident&#153; at the Gene (two emergency eboard meetings in one day :'))</p>
        <p>Mega-band with Cornell (Defeated them in an honorable Yell A duel)</p>
        <p>WHKY Played at Blue Cross for the First Time</p>
        <p>Mega-band with Sacred Heart</p>
        <p>Double size band at semi-finals</p>
        <p>Band in the stands for the Amerks (at Blue Cross!!) for the first time</p>
        <p>Constitutional Amendment: Webmaster Counts toward Section Leader Quorum for votes</p>
        <p><b>Max Attendance at an Event: </b></p>
    </div>
  );
}
