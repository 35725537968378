import React from 'react';
import '../../css/chants.css';

export default function Chants(props) {
  return (
    <div id="chants">
      <h2><u>Chants</u></h2>
      <h3>After an Icing</h3>
      <div className={`${props.noCSS ? '' : 'chantBody'}`}>
        <b>Men's Games:</b>
        <p>
          If you suck and you know it ice the puck (Clap clap)<br />
          If you suck and you know it ice the puck (Clap clap)<br />
          If you suck and you know it and you really want to show it<br />
          If you suck and you know it ice the puck (Clap clap)
        </p>
        <p>(For Women's or Service Academy Games, replace "you suck" with "you're bad")</p>
        <p>
          If you can't hit the net hit the ref (Clap clap)<br />
          If you can't hit the net hit the ref (Clap clap)<br />
          If you can't hit the net 'cause your shooting grade's an F <br />
          If you can't hit the net hit the ref (Clap clap)
        </p>
        <br />
        <b>If it is a State School:</b>
        <p>
          If you can't go to college go to state (Clap clap)<br />
          If you can't go to college go to state (Clap clap)<br />
          If you can't go to college 'cause you can't afford the knowledge<br />
          If you can't go to college go to state (Clap clap)
        </p>
        <br />
        <b>If it is a Religious School:</b>
        <p>
          If you can't go to college go to church (Clap clap)<br />
          If you can't go to college go to church (Clap clap)<br />
          If you can't go to college 'cause you don't believe in knowledge<br />
          If you can't go to college go to church (Clap clap)
        </p>
        <b>If it is Chair Force (Air Force):</b>
        <p>
          If you can't join the Corp, join the Force! (Clap clap)<br />
          If you can't join the Corp, join the Force! (Clap clap)<br />
          If you can't join the Corp, cause you like your Desk Job more<br />
          If you can't join the Corp, join the Force (Clap clap)<br />
        </p>
      </div>
      <br/>
      <h3>In-between the 2nd and 3rd Verses of Hey Song</h3>
      <div className={`${props.noCSS ? '' : 'chantBody'}`}>
        <p>
          We're gonna score so easily<br />
          Shoot, pass, kick their ass RIT!
        </p>
      </div>
      <br/>
      <h3>When their Goalie gets pulled</h3>
      <div className={`${props.noCSS ? '' : 'chantBody'}`}></div>
      <p>
        *Point at Our Goalie* GOALIE<br />
        *Point at Their Goalie on the bench* SIEVE<br />
        *Point at Open Net* NET<br />
        (Repeat until bored)
      </p>
      <div className={`${props.noCSS ? '' : 'chantBody'}`}></div>
      <br/>
      <h2><u>Corner Crew Chants</u></h2>
      <h3>Strawberry Shortcake</h3>
      <div className={`${props.noCSS ? '' : 'chantBody'}`}>
        <p>
          Strawberry shortcake, banana split<br />
          Your team stinks like a pile of<br />
          Shift to the left, shift to the right, stand up sit down fight fight fight!
        </p>
      </div>
      <br />
      <h3>E^x</h3>
      <div className={`${props.noCSS ? '' : 'chantBody'}`}>
        <p>
          E TO THE X D X D Y<br />
          Secant Cosine Tangent Sine<br />
          3.14159<br />
          PI<br />
          I times 3<br />
          Let's go let's go RIT
        </p>
      </div>

      <div hidden> {/* remove the "hidden" keyword to reveal graveyard */}
        <div>
          <br />
          <h2>Chant Graveyard</h2>
          <p>
            <i>
              For those chants who didn't deserve to die but did anyways<br />
              Disclaimer: Most of these died because they are dumb or are <u>TOO</u> offensive
            </i>
          </p>
        </div>
        <br />
        <h3>Shit Don't Float</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p>
            Guy: HEY REF YOU'RE LUCKY THE ICE IS FROZEN<br />
            Everyone: 'CAUSE SHIT DON'T FLOAT
          </p>
        </div>
        <br />
        <h3>When their team (or a ref) falls down</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <b>If it's a guy:</b>
          <p>
            He fell down<br />
            He can't skate<br />
            He can't even masturbate
          </p>
          <b>If it's a girl:</b>
          <p>
            She fell down<br />
            She can't skate<br />
            She can't even integrate
          </p>
        </div>
        <br />
        <h2>Goal Celebrations</h2>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p><i>Note: None of these happen except for Goalie Sieve Net</i></p>
          <b>3 Goals:</b>
          <p>
            ROTOFIVE - right hand facing forward, left hand facing backward and then spin<br />
            counterclockwise high fiving the people next to you repeatedly
          </p>
          <p><i>Bonus: It would be really cool to get the whole corner crew to do this</i></p>
          <br />
          <b>5 Goals:</b>
          <p>
            RICK FLAIR CELEBRATION - yelling woooooooo repeatedly while pretending to spin a<br />
            siren (video will <i>(probably not)</i> come soon)</p>
          <br />
          <b>Rick 'em Rack 'em</b>
          <p>Rick ‘em Rack ‘em Rick ‘em Ruck ‘em Get that Puck and Really Fight</p>
          <br />
          <b>Hefty Hefty Hefty Wimpy Wimpy Wimpy</b>
          <p>(deep voice) HEFTY HEFTY HEFTY… (high pitched voice) WIMPY WIMPY WIMPY</p>
          <br />
        </div>
        <h3>REKT</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p>R-E-K-T REKT REKT REKT</p>
        </div>
        <br />
        <h3>Florida Oranges</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p>
            Florida Oranges<br />
            Texas Cactus<br />
            We use your team just for practice
          </p>
        </div>
        <br />
        <h3>Refs are Out to Lunch</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p>
            The refs are out to lunch<br />
            The refs are out to lunch<br />
            Eat me ref eat me ref<br />
            MUNCH MUNCH MUNCH
          </p>
        </div>
        <br />
        <h3>Unknown Title</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p>
            1-2-1-2 and thru and thru<br />
            Our vorpal blades went snicker snack<br />
            We shot the puck at the little schmuck and left him on his back HEY!
          </p>
        </div>
        <br />
        <h3>To Fall or Not To Fall</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p>
            To flee or not to flee, that is the question<br />
            Whether ‘tis nobler in the mind to pit pad<br />
            Against puck from the ferocious feline, or to take<br />
            Flight and return to the bench from whence you came<br />
            To hide, to weep, once more<br />
            And while you weep just know that net<br />
            Stands empty due to you<br />
            Take solace not in destiny, or even rotten luck<br />
            Just know the simple fact dear goalie is you just really<br />
            SUCK
          </p>
        </div>
        <br />
        <h3>Nuts and Bolts</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p>NUTS AND BOLTS NUTS AND BOLTS…. WE… GOT… SCREWED!</p>
        </div>
        <br />
        <h3>Elevator</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p>Elevator elevator WE GOT THE SHAFT</p>
        </div>
        <br />
        <h3>Fire Truck</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p>FIRE TRUCK FIRE TRUCK WE GOT HOSED!</p>
        </div>
        <br />
        <h3>A Rope A Tree</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p>
            A rope a tree to hand the referee<br />
            Some bullets a gun to shoot the other ones
          </p>
        </div>
        <br />
        <h3>Four Blind Refs</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p>
            Four blind refs<br />
            Four blind refs<br />
            See how they skate<br />
            See how they skate<br />
            They all went after the coaches wife she cut off their balls with a carving knife<br />
            You’ve never seen such a sight in your life as<br />
            Four blind refs<br />
            Four blind refs
          </p>
        </div>
        <br />
        <h3>Where'd He Learn to Skate</h3>
        <div className={`${props.noCSS ? '' : 'chantBody'}`}>
          <p>
            1-2-3-4<br />
            He fell on the icy floor<br />
            5-6-7-8<br />
            Where the heck’d he learn to skate?
          </p>
        </div>
      </div>
    </div>
  )
}
