import React from 'react';

export default function History1819() {
  return (
    <div className='History'>
      <h2>History 2018-2019</h2>
      <h2>E-Board:</h2>
        <p><b>President: </b>Sarah Messner</p>
        <p><b>Vice President: </b>Alan Kuffner</p>
        <p><b>Vice President: </b>Matt Robison</p>
        <p><b>Secretary: </b>Bao Thai</p>
        <p><b>Treasurer: </b>Christine Miller</p>
        <p><b>Conductor: </b>Kyrsia Browka</p>
        <p><i>Conductor Attire:</i> Glasses?</p>

      <h3>Accomplishments and Achievements</h3>
        <p><b>Max Attendance at an Event: </b></p>
    </div>
  );
}
