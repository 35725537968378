import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import { authRole } from '../../../index';
import '../../../css/qdb.css';
import { USERID } from '../../App';
import { Link } from 'react-router-dom';

export default function Home(props) {
  const [quotes, setQuotes] = useState([])
  const [filtered, setFiltered] = useState([])

  useEffect(() => {
    const url = `${props.constants.hostname}/api/qdb/public`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then((data) => {
      if (!data) { return; }
      setQuotes(data)
      setFiltered(data)
    })
    .catch((error) => { console.error(error); });
  }, [])

  function handleSearch(event) {
    const filteredQuotes = [];
    const searchVal = event.target.value.toLowerCase();
    quotes.filter((quote) => {
      if (quote.Title.toLowerCase().includes(searchVal)
        || quote.Person.toLowerCase().includes(searchVal)
        || quote.Quote.toLowerCase().includes(searchVal)
      ) {
        filteredQuotes.push(quote);
      }
      return filteredQuotes;
    })
    setFiltered(filteredQuotes)
  }

  function confirm(qid) {
    if (window.confirm('Delete this quote?')) {
      const url = `${props.constants.hostname}/api/qdb/removeQuote`;
      fetch(url, {
        body: JSON.stringify({QID: qid}),
        headers: {
          Authorization: `${localStorage.getItem(USERID)}`,
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
      })
      .then((res) => {
        this.setState({delete: true})
        if (!res.okay) {
          return false;
        }
        return res.json();
      })
      .then(() => {
        const tempQuotes = [];
        quotes.forEach((quote) => {
          if (quote.QID !== qid) {
            tempQuotes.push(quote);
          }
        });
        setQuotes(tempQuotes)
        setFiltered(tempQuotes)
      })
      .catch((error) => { console.error(error); });
    }
  }

  return (
    <div id='Qdb'>
      <h2>QDB</h2>
        <div className='oneline'>
          <Link to='/qdb/addQuote' className='secBarLink'>
            <div className='oneline'>
              <FontAwesomeIcon icon='plus-circle' className='faAdd'/>
              <p className='secBarText'>
                Add Quote
              </p>
            </div>
          </Link>
          {authRole(props.userRole, props.constants.roles.president) ?
            <Link to='/qdb/approveQuote' className='secBarLink'>
              <div className='oneline'>
                <FontAwesomeIcon icon='check-square' className='faAdd'/>
                <p className='secBarText'>
                  Approve Quotes
                </p>
              </div>
              <br/>
            </Link>
            :
            null
          }
        </div>
      <hr/>
      <br/>
      <div className='oneline center'>
        <p className='searchTag'><b>Search:</b></p>
        <input
          onChange={(e) => handleSearch(e)}
          className='quoteSearch'
          placeholder='Search for quotes...'
        />
      </div>
      <div className='qdbTables'>
        {filtered.map((quote) =>
          <div id={quote.QID} key={quote.QID}>
            <table className='QDBTable'>
              <tbody>
                <tr className='QDBTitle'>
                  <th colSpan='2'>{quote.Title}</th>
                </tr>
                <tr className='QDBRow quoteRow'>
                  <td colSpan='2' className='quote multiline'>
                    <ReactMarkdown>{quote.Quote}</ReactMarkdown>
                  </td>
                </tr>
                <tr className='QDBRow secondary'>
                  <td className='quotePerson'>-{quote.Person}</td>
                  <td>
                    {authRole(props.userRole, props.constants.roles.admin) ?
                      <FontAwesomeIcon
                        icon='trash-alt'
                        className='faDelete qdbDelete'
                        onClick={() => confirm(quote.QID)}
                      />
                      :
                      null
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
