import React from 'react';

export default function History1213() {
  return (
    <div className='History'>
      <h2>History 2012-2013</h2>
      <h1>THE DARK AGES</h1>
      <h2>E-Board:</h2>
        <p><b>President: </b>Gabrielle Potts</p>
        <p><b>Vice President: </b>Brian Besmanoff</p>
        <p><b>Secretary: </b>Kaity Moranz</p>
        <p><b>Treasurer: </b>Teryn Rynone</p>
        <p><b>Conductor: </b>Jimmy McNatt</p>
        <p><i>Conductor Attire:</i></p>

      <h3>Accomplishments and Achievements</h3>
        <p><b>Max Attendance at an Event: ??</b></p>
    </div>
  );
}
