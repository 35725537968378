import React from 'react';

export default function History0809() {
  return (
    <div className='History'>
      <h2>History 2008-2009</h2>
      <h2>E-Board:</h2>
        <p><b>President: </b>Mark Negro</p>
        <p><b>Vice President: </b>Patrick Sweazy</p>
        <p><b>Secretary: </b>Amanda Preske </p>
        <p><b>Treasurer: </b>Rachel Rattner</p>
        <p><b>Conductor: </b>Mike Polimini</p>
        <p><i>Conductor Attire:</i> Black Suit Jacket over a white shirt, Black Pants, tiger print tie, fedora hat with Bare Hands</p>

      <h3>Graduating Members</h3>
        <p>Chris Daniels</p>
        <p>Jessie Muszinski</p>
        <p>Julie Zepke</p>
        <p>Pat McDermott (Keyboard)</p>
        <p>Trevor West</p>

      <h3>New Music</h3>
        <p>Birdland</p>
        <p>Hey Baby</p>
        <p>Land of 1000 dances</p>
        <p>Let Me Clear My Throat</p>
        <p>Magnificent 7</p>
        <p>O Canada</p>
        <p>Star Spangled Banner</p>
        <p>Vehicle</p>
        <p>You Can Call Me Al</p>

      <h3>Accomplishments and Achievements</h3>
        <p>Played outside Commons for Pep Band Day</p>
        <p>Played at Women's Games</p>
        <p>Played at Spring Fest</p>
        <p>Travelled to Niagara University</p>
        <p>Recorded the Band</p>
        <p>Made Sousaphone Covers</p>
        <p>Purchased Sound Equipment for electric instruments</p>
        <p>Purchased mellophones</p>
        <p>Purchased RIT Pep Band Banner</p>
        <p>KD the Comic Fundraiser</p>
        <p>Designed T-shirts</p>
        <p>Electric Violin in the band</p>
        <p>Ordered Lyres and Flipbooks</p>
        <p>Ordered Trombone Slide Covers</p>
        <p>Performed the National Anthems</p>
        <p>Rick Rolled Blue Cross Arena</p>
        <p><b>Max Attendance at an Event: 55</b></p>
    </div>
  );
}
