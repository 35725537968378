export const blankCheck = (testInput) => {
  if (testInput === '') {
    return true;
  }
  return false;
}

export const nameCheck = (testInput) => {
  const nameRegex = /^[a-z ,.-]+$/i
  if (!nameRegex.test(testInput)) {
    return true;
  }
  return false;
}

export const emailCheck = (testInput) => {
  const emailRegex = /^[a-z0-9@.]+$/
  if (!emailRegex.test(testInput)) {
    return true;
  }
  if (!testInput.includes('@rit.edu') && !testInput.includes('@g.rit.edu')) {
    return true;
  }
  return false;
}

export const dceCheck = (testInput) => {
  const dceRegex = /^[a-z0-9]+$/
  if (!dceRegex.test(testInput)) {
    return true;
  }
  return false;
}

export const sectionCheck = (testInput) => {
  const sections = ['Flutes', 'Clarinets', 'Saxophones', 'Trumpets', 'Mellophones', 'Tenor Saxophones', 'Trombones', 'Tubas', 'Percussion']
  if (!sections.includes(testInput)) {
    return true;
  }
  return false;
}

export const eventCheck = (testInput) => {
  const eventRegex = /^[a-z0-9 ,.-]+$/i
  if (!eventRegex.test(testInput)) {
    return true;
  }
  return false;
}

export const passCheck = (testInput) => {
  const passRegex = /^[a-z0-9 ,.$#@!^&*%-]+$/i
  if (!passRegex.test(testInput)) {
    return true;
  }
  return false;
}

export const dateCheck = (testInput) => {
  const now = new Date();
  const input = new Date(testInput);
  if (input.getFullYear() !== now.getFullYear() &&
    input.getFullYear() - 1 !== now.getFullYear()) {
    return true;
  }
  return false;
}

export const cellCheck = (testInput) => {
  if (testInput.length === 0) {
    return false;
  }
  const cellRegex = /^[a-z0-9]{10}$/i
  return (!cellRegex.test(testInput));
}
