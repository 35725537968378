import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './VotingPages/Home';
import CreateElection from './VotingPages/CreateElection';
import Results from './VotingPages/Results'
import Election from './VotingPages/Election';

export default function Vote(props) {
  return (
    <div id='Vote'>
      <h2>PB&J System</h2>
      <Routes>
        <Route path='' element={<Home constants={props.state.constants} userRole={props.state.userRole}/>}/>
        <Route path='election/:sElection' element={<Election constants={props.state.constants}/>}/>
        <Route path='create' element={<CreateElection constants={props.state.constants} />} />
        <Route path='results' element={<Results constants={props.state.constants}/>} />
      </Routes>
    </div>
  )
}
