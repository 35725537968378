import React from 'react';

export default function NotFound() {
    return (
      <div id='notFound'>
          <h2>404: Page not found</h2>
          <p>Please navigate to a different page to make sad RITchie feel better</p>
          <img src='/media/sadtiger.jpg'
            alt='Sad tiger :('/>
      </div>
    );
}
